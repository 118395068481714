import { ImageOutlined, UploadFileOutlined } from "@mui/icons-material";
import { Button as ButtonPrimitive, FileTrigger } from "react-aria-components";
import { Product } from "../../api/types";

import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { useImageAccessLink, useUploadImage } from "../../api/endpoints/manufacturers";
import { apiLink } from "../../api/useApi";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useUpdateProduct } from "../../state/products";
import { showErrorToast } from "../../util/toasts";
import { useValidateFileSize } from "../../util/validateFileSize";

const SxButton = tw(ButtonPrimitive)`
  w-full
  h-full
  z-20
  absolute
  bg-neutral-900
  bg-opacity-40
  top-0
  left-0
  opacity-0
  group-hover:opacity-100
  transition-opacity
  cursor-pointer
  text-white
  flex
  items-center
  justify-center
`;

function useProductImage(product: Product) {
  const defaultImageUrl = useMemo(
    () => apiLink(`/static${product.metadata.img_url}`),
    [product.metadata.img_url],
  );

  const [imageSrc, setImageSrc] = useState<string | null>(defaultImageUrl);
  const { activeManufacturer } = useActiveManufacturer();
  const [loading, setLoading] = useState(false);
  const getImageAccessLink = useImageAccessLink();

  useEffect(() => {
    const loadCustomImage = async () => {
      if (!product?.image_url) {
        return;
      }

      setLoading(true);

      try {
        const img = await getImageAccessLink({
          path: product.image_url,
        });
        setImageSrc(img);
      } catch (error) {
        console.error("Error generating image url:", error);
      } finally {
        setLoading(false);
      }
    };

    loadCustomImage();
  }, [product.image_url, activeManufacturer.id, getImageAccessLink]);

  return loading ? null : imageSrc;
}

export const ProductImage = memo(
  ({ product, disableUpload = false }: { product: Product; disableUpload?: boolean }) => {
    const { t } = useTranslation();
    const uploadImage = useUploadImage();
    const { mutate: updateProduct } = useUpdateProduct();
    const validateFileSize = useValidateFileSize();

    const imageSrc = useProductImage(product);

    const handleFileDrop = async (files: FileList | null) => {
      const file = files?.[0];
      if (!file) return;

      try {
        validateFileSize(file);
        const key = await uploadImage({
          file,
        });
        updateProduct({
          productId: product.id,
          product: { ...product, image_url: key },
        });
      } catch (_) {
        showErrorToast(t("Couldn't upload product image."));
      }
    };

    // Note: we're using the padding-trick to keep the image square, responsively
    return (
      <div className="shrink-0 p-[2px] bg-white relative group w-full pb-[100%]">
        {!imageSrc ? (
          <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
            <ImageOutlined className="text-neutral-300" fontSize="large" />
          </div>
        ) : (
          <img
            className="w-full h-full absolute left-0 top-0 z-10 object-cover select-none pointer-events-none"
            src={imageSrc}
            alt="Product"
          />
        )}
        {!disableUpload && (
          <FileTrigger onSelect={handleFileDrop}>
            <SxButton>
              <UploadFileOutlined fontSize="large" />
            </SxButton>
          </FileTrigger>
        )}
      </div>
    );
  },
);
