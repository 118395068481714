import { Suspense } from "react";
import { useParams } from "react-router";
import { SuppliersAndMaterialsForm } from "../page-components/suppliers-and-materials/SuppliersAndMaterialsForm";
import { useMaterial } from "../state/materials";

export const EditMaterial = () => {
  const { id } = useParams();
  const existingMaterial = useMaterial(id);

  if (!id || !existingMaterial) return null;

  return (
    <Suspense>
      <SuppliersAndMaterialsForm existingMaterial={existingMaterial} />
    </Suspense>
  );
};
