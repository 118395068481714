import { cva } from "class-variance-authority";
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button as ButtonPrimitive } from "react-aria-components";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router";
import { isButton, isGroup, isLink, NavItem, NavItemLink } from "../config/useNavbarItems";
import { Label14, Label16 } from "./Typography";

const itemContainerStyles = cva(
  [
    "flex",
    "items-center",
    "outline-none",
    "gap-4",
    "py-3",
    "px-4",
    "cursor-pointer",
    "hover:bg-neutral-200",
    "active:bg-neutral-200",
    "group",
  ],
  {
    variants: {
      isActive: {
        true: ["bg-neutral-200"],
      },
      collapsed: {
        true: ["pl-4", "justify-end"],
      },
    },
    defaultVariants: {
      collapsed: false,
    },
  },
);

const childContainerStyles = cva(
  ["flex", "items-center", "gap-4", "py-2", "px-4", "cursor-pointer", "group"],
  {
    variants: {
      collapsed: { true: ["pl-4", "justify-end"] },
      isActive: { true: "[&>p]:text-builtgreen", false: "[&>p]:text-neutral-400" },
    },
  },
);

const childrenGroupStyles = cva([
  "flex",
  "flex-col",
  "[&>:first-child]:pt-2",
  "[&>:last-child]:pb-2",
  "overflow-hidden",
  "transition-[height]",
  "duration-200",
  "ease-in-out",
]);

const parsePath = (path: string) => {
  const [pathname, search] = path.split("?");
  return {
    pathname,
    searchParams: new URLSearchParams(search || ""),
  };
};

const hasMatchingSearchParams = (
  itemParams: URLSearchParams,
  activeParams: URLSearchParams,
  { exact = false }: { exact?: boolean } = {},
) => {
  if (exact && itemParams.size !== activeParams.size) {
    return false;
  }

  for (const [key, value] of itemParams) {
    if (activeParams.get(key) !== value) {
      return false;
    }
  }
  return true;
};

const usePathsMatch = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  return useCallback(
    (itemPath: string, { exact = false }: { exact?: boolean } = {}) => {
      const { pathname: itemPathname, searchParams: itemParams } = parsePath(itemPath);

      const pathMatches =
        pathname === itemPathname || (!exact && pathname.startsWith(itemPathname));

      return pathMatches && hasMatchingSearchParams(itemParams, searchParams, { exact });
    },
    [pathname, searchParams],
  );
};

const isSomeChildActive = (children: NavItem[], isMatch: ReturnType<typeof usePathsMatch>) => {
  return children.some((child) => isLink(child) && isMatch(child.path, { exact: true }));
};

/**
 * In case the user navigates directly to a page that is a child of a group,
 * we want to open the group.
 */
const useOpenCurrentGroupOnPageEnter = ({
  isActive,
  isGroupOpen,
  openCurrentGroup,
}: {
  isActive: boolean;
  isGroupOpen: boolean;
  openCurrentGroup: () => void;
}) => {
  // Make sure to trigger the effect only when the child mounts
  // or when its active state changes.
  // Needs to support the following use cases:
  // - we enter the page on a subpage directly, and the group should open
  // - we go back / forward in the browser, and the correct group should open
  // - when navigating / clicking the navbar, we don't want to create any lag,
  //   / avoiding an additional call to openCurentGroup
  const isGroupOpenRef = useRef(isGroupOpen);
  isGroupOpenRef.current = isGroupOpen;
  const openCurrentGroupRef = useRef(openCurrentGroup);
  openCurrentGroupRef.current = openCurrentGroup;

  useEffect(
    function openActiveGroupOnPageEnter() {
      if (isActive && !isGroupOpenRef.current) {
        openCurrentGroupRef.current();
      }
    },
    [isActive],
  );
};

const NavbarItemChild = memo(function NavbarItemChildComponent({
  child,
  collapsed,
  isMatch,
  isGroupOpen,
  openCurrentGroup,
}: {
  child: NavItemLink;
  collapsed?: boolean;
  isMatch: ReturnType<typeof usePathsMatch>;
  isGroupOpen: boolean;
  openCurrentGroup: () => void;
}) {
  const isActive = isMatch(child.path, { exact: true });

  useOpenCurrentGroupOnPageEnter({ isActive, isGroupOpen, openCurrentGroup });

  return (
    <Link key={child.name} to={child.path}>
      <div className={childContainerStyles({ collapsed, isActive })}>
        <div className="grid size-6 place-items-center shrink-0">
          <div
            className={`size-2 rounded-full ${isActive ? "bg-steelblue" : "bg-neutral-400"} group-hover:bg-steelblue`}
          />
        </div>
        {!collapsed && <Label14>{child.name}</Label14>}
      </div>
    </Link>
  );
});

export const NavbarItem = memo(function NavbarItemComponent({
  item,
  collapsed,
  openNavGroup,
  setOpenNavGroup,
}: {
  item: NavItem;
  collapsed?: boolean;
  openNavGroup: string | null;
  setOpenNavGroup: (group: string | null) => void;
}) {
  const isOpen = openNavGroup === item.name;
  const isMatch = usePathsMatch();

  const isActive =
    (isLink(item) && isMatch(item.path)) ||
    (isGroup(item) && isSomeChildActive(item.children, isMatch));

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  const navigate = useNavigate();

  const openCurrentGroup = useCallback(() => {
    setOpenNavGroup(item.name);
  }, [setOpenNavGroup, item.name]);

  if (isGroup(item)) {
    return (
      <div className="grid overflow-hidden">
        <ButtonPrimitive
          className={itemContainerStyles({ collapsed, isActive })}
          onPress={() => {
            setOpenNavGroup(isOpen ? null : item.name);
            if (!isOpen) {
              const firstChild = item.children[0];
              if (firstChild && isLink(firstChild)) {
                // To avoid the open / close animation lagging when a new page is mounting
                setTimeout(() => navigate(firstChild.path), 200);
              }
            }
          }}
        >
          <div className="size-6 grid place-items-center">{item.icon}</div>
          {!collapsed && <Label16>{item.name}</Label16>}
        </ButtonPrimitive>
        <div
          className={childrenGroupStyles()}
          style={{ height: isOpen ? contentHeight : 0 }}
          ref={contentRef}
        >
          {item.children.filter(isLink).map((child) => (
            <NavbarItemChild
              key={child.name}
              child={child}
              collapsed={collapsed}
              isMatch={isMatch}
              isGroupOpen={isOpen}
              openCurrentGroup={openCurrentGroup}
            />
          ))}
        </div>
      </div>
    );
  }

  if (isButton(item)) {
    return (
      <ButtonPrimitive className="outline-none" onPress={item.onPress}>
        <ItemInner item={item} collapsed={collapsed} isActive={isActive} />
      </ButtonPrimitive>
    );
  }

  return (
    <Link
      to={item.path}
      onClick={(e) => {
        // To avoid the open / close animation lagging when a new page is mounting
        e.preventDefault();
        setOpenNavGroup(null);
        setTimeout(() => navigate(item.path), 100);
      }}
    >
      <ItemInner item={item} collapsed={collapsed} isActive={isActive} />
    </Link>
  );
});

const ItemInner = ({
  item,
  collapsed,
  isActive,
}: {
  item: NavItem;
  collapsed?: boolean;
  isActive: boolean;
}) => {
  return (
    <div className={itemContainerStyles({ collapsed, isActive })}>
      <div className="size-6 grid place-items-center">{item.icon}</div>
      {!collapsed && <Label16>{item.name}</Label16>}
    </div>
  );
};
