import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  useGetSupplierPlants,
  useGetSuppliers,
  usePostSupplierPlants,
  usePostSuppliers,
} from "../api/endpoints/suppliers";
import { useActivePlant } from "./plants";

export const useSuppliers = () => {
  const activePlant = useActivePlant();
  const getSuppliers = useGetSuppliers();

  return useSuspenseQuery({
    queryKey: [activePlant.id, "suppliers"],
    queryFn: () => getSuppliers({ plantId: activePlant.id }),
  });
};

export const useAddSupplier = () => {
  const queryClient = useQueryClient();
  const activePlant = useActivePlant();
  const postSuppliers = usePostSuppliers();

  return useMutation({
    mutationFn: (supplier: { name: string }) =>
      postSuppliers({ plantId: activePlant.id, supplier }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "suppliers"],
        exact: true,
      });
    },
  });
};

export const useSupplierPlants = (supplierId?: string) => {
  const activePlant = useActivePlant();

  const getSupplierPlants = useGetSupplierPlants();

  return useQuery({
    queryFn: () => {
      if (!supplierId) throw new Error();

      return getSupplierPlants({
        plantId: activePlant.id,
        supplierId,
      });
    },
    queryKey: [activePlant.id, "suppliers", supplierId, "supplierPlants"],
    enabled: !!supplierId,
  });
};

export const useAddSupplierPlant = () => {
  const queryClient = useQueryClient();
  const activePlant = useActivePlant();
  const postSupplierPlants = usePostSupplierPlants();

  return useMutation({
    mutationFn: ({
      supplierId,
      supplierPlant,
    }: {
      supplierId: string;
      supplierPlant: Parameters<typeof postSupplierPlants>[0]["supplierPlant"];
    }) =>
      postSupplierPlants({
        plantId: activePlant.id,
        supplierId,
        supplierPlant,
      }),
    onSuccess: (_, { supplierId }) => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "suppliers", supplierId, "supplierPlants"],
        exact: true,
      });
    },
  });
};
