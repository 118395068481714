import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useProductionProcesses } from "../../api/endpoints/production-processes";
import { Plant, ProductCategory } from "../../api/types";
import { Alert } from "../../components/Alert";
import { Checkbox, CheckboxGroupField } from "../../components/CheckboxGroupField";
import { Label12 } from "../../components/Typography";
import { useElementaries } from "../../state/elementaries";
import { useProductCategories } from "../../state/productCategories";
import { exists } from "../../util/commonUtil";

// This is essentially just a custom ComboBoxMultiFieldConnected
//
// <ComboBoxMultiFieldConnected
//   name="product_categories"
//   options={options}
//   label={t("what do you produce in this plant?")}
//   wrapValueAt={5}
//   isrequired
// />

const CustomProductCategoryPickerBase = ({
  notRemovableCategories,
}: {
  notRemovableCategories: Set<ProductCategory["id"]>;
}) => {
  const {
    data: { productCategories },
  } = useProductCategories();
  const { watch, setValue, setError, clearErrors, formState } = useFormContext();
  const { t } = useTranslation();
  const selectedCategories: string[] = watch("product_categories");
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setError("product_categories", { type: "custom", message: t("Required") });
      setHasError(true);
    } else {
      clearErrors("product_categories");
      setHasError(false);
    }
  }, [selectedCategories, setError, clearErrors, t]);

  const groupedCategories = useMemo(() => {
    return Object.groupBy(productCategories, (cat) => cat.group);
  }, [productCategories]);

  return (
    <div className="space-y-6">
      <div>
        <Label12 className="text-neutral-400">{t("What do you produce in this plant?")}</Label12>
        <hr className="border-neutral-200 mt-4" />
        {hasError && formState.submitCount > 0 && (
          <span className="text-red-500 text-sm font-bold">
            {t("At least one product category required")}
          </span>
        )}
      </div>
      <div
        className="grid gap-6"
        style={{
          gridTemplateColumns: `repeat(${Object.entries(groupedCategories).length}, 1fr)`,
        }}
      >
        {Object.entries(groupedCategories).map(([category, items]) => (
          <CheckboxGroupField
            key={category}
            value={selectedCategories.filter((cat) => items?.map((i) => i.id).includes(cat))}
            onChange={(value) => {
              setValue(
                "product_categories",
                Array.from(
                  new Set([
                    ...selectedCategories.filter((cat) => !items?.map((i) => i.id).includes(cat)),
                    ...value,
                  ]),
                ),
              );
            }}
            label={t(category)}
            labelProps={{ style: { color: "var(--builtgreen)" } }}
          >
            {items!.map((category) => {
              return (
                <Checkbox
                  key={category.id}
                  value={category.id}
                  isDisabled={notRemovableCategories.has(category.id)}
                >
                  {t(category.name)}
                </Checkbox>
              );
            })}
          </CheckboxGroupField>
        ))}
      </div>
      {notRemovableCategories.size > 0 && (
        <div className="justify-self-end">
          <Alert intent="info">
            {t("Note: categories with a production process may not be deselected")}
          </Alert>
        </div>
      )}
    </div>
  );
};

const CustomProductCategoryPickerNewPlant = () => {
  return <CustomProductCategoryPickerBase notRemovableCategories={new Set()} />;
};

const CustomProductCategoryPickerExistingPlant = ({ activePlant }: { activePlant: Plant }) => {
  const { elementariesMap } = useElementaries();

  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });

  const notRemovableCategories: Set<ProductCategory["id"]> = useMemo(
    () =>
      new Set(
        productionProcesses.flatMap((p) =>
          p.elementary_ids.map((e) => elementariesMap[e].product_category_id).filter(exists),
        ),
      ),
    [elementariesMap, productionProcesses],
  );

  return <CustomProductCategoryPickerBase notRemovableCategories={notRemovableCategories} />;
};

export const CustomProductCategoryPicker = ({ activePlant }: { activePlant?: Plant }) => {
  if (!activePlant) return <CustomProductCategoryPickerNewPlant />;

  return <CustomProductCategoryPickerExistingPlant activePlant={activePlant} />;
};
