import { FactoryOutlined } from "@mui/icons-material";
import { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { LanguageSwitch } from "../localization/LanguageSwitch";
import { DataExport } from "../page-components/company-settings/DataExport";
import { ManageCompanyLogo } from "../page-components/company-settings/ManageCompanyLogo";
import { ManageManufacturers } from "../page-components/company-settings/ManageManufacturers";
import { useActiveManufacturer } from "../state/manufacturers";
import { ManagePlants } from "./ManagePlants";
import { ManageProfile } from "./ManageProfile";
import { ManageUsers } from "./ManageUsers";

const CompanySettingsBody = ({ activeTab }: { activeTab: string }) => {
  const { activeManufacturer } = useActiveManufacturer();

  return (
    <div className="flex flex-col gap-12 py-8">
      {activeTab === "company" && (
        <>
          <ManageManufacturers activeManufacturer={activeManufacturer} />
          <ManageCompanyLogo />
        </>
      )}
      {activeTab === "plants" && (
        <>
          <ManagePlants />
          <DataExport />
        </>
      )}
      {activeTab === "users" && (
        <>
          <ManageUsers />
          <ManageProfile />
        </>
      )}
    </div>
  );
};

export const CompanySettings = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || "company";

  const title = useMemo(() => {
    if (activeTab === "company") return t("Company Settings");
    if (activeTab === "plants") return t("Production Plants");
    if (activeTab === "users") return t("Users");
    return t("Company Settings");
  }, [activeTab, t]);

  return (
    <PageContainer>
      <TopBar title={title} icon={<FactoryOutlined />} input={<LanguageSwitch />} />
      <Suspense>
        <CompanySettingsBody activeTab={activeTab} />
      </Suspense>
    </PageContainer>
  );
};
