import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useDeleteMaterial } from "../../api/endpoints/materials";
import { SupplierMaterial } from "../../api/types";
import { useActivePlant } from "../../state/plants";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { showSuccessToast } from "../../util/toasts";

export const useMaterialsDeletion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const [deletionStaged, setDeletionStaged] = useState(false);
  const deleteMaterial = useDeleteMaterial();
  const queryClient = useQueryClient();

  const activePlant = useActivePlant();

  const location = useLocation();
  const routeAfterDeletion = "/suppliers-and-materials";

  const removeMaterials = async (materialIds: SupplierMaterial["id"][]) => {
    if (!activePlant || materialIds.length === 0) return;

    try {
      await Promise.all(
        materialIds.map((id) =>
          deleteMaterial({
            materialId: id,
            plantId: activePlant.id,
          }),
        ),
      );

      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
      });

      setDeletionStaged(false);
      showSuccessToast(
        materialIds.length === 1
          ? t("Successfully deleted material")
          : t("Successfully deleted materials"),
      );

      if (location.pathname !== routeAfterDeletion) {
        navigate(getLinkWithParams(routeAfterDeletion));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return { deletionStaged, setDeletionStaged, removeMaterials };
};
