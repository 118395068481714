import { useTranslation } from "react-i18next";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { ConcreteScenario } from "./ProductSpecsConcreteScenario";

export type BaggedConcreteScenario = ConcreteScenario & {
  water_amount_kg: number;
};

export const BaggedConcreteInstallationFields = () => {
  const { t } = useTranslation();

  return (
    <NumberFieldConnected
      name="scenario.water_amount_kg"
      minValue={0}
      label={t("Amount of water used for mixing")}
      isRequired
      inputProps={{
        addonRight: "liter", // Liter or kg is the same for water
      }}
    />
  );
};
