import { AddOutlined, Commit, Inventory2Outlined, TokenOutlined } from "@mui/icons-material";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { CircularProgress } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { Suspense, useMemo } from "react";
import { MenuTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { Impact, PlantMaterial } from "../api/types";
import { Badge } from "../components/Badge";

import { Button } from "../components/Button";
import { DataGrid } from "../components/DataGrid";
import { Menu, MenuItem } from "../components/Menu";
import { PageContainer } from "../components/PageContainer";
import { StatusIndicator } from "../components/StatusIndicator";
import { TopBar } from "../components/TopBar";
import { Label, Label16 } from "../components/Typography";
import { useElementaries } from "../state/elementaries";
import { useMaterialImpacts, useMaterials } from "../state/materials";
import { usePrechainProductImpacts } from "../state/prechainProducts";
import { useSuppliers } from "../state/suppliers";
import { useGetLinkWithParams } from "../url/useGetLinkWithParams";
import { exists } from "../util/commonUtil";
import { formatPrecision } from "../util/format";
import { useUrlPaginationSettings } from "../util/useUrlPaginationSettings";

export const SuppliersAndMaterials = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { rawMaterials, packagingMaterials } = useMaterials();

  const { data: suppliers } = useSuppliers();

  const input = useMemo(
    () => (
      <>
        <Button intent="secondary" onPress={() => navigate("/bulk-upload/materials")}>
          {t("Upload")}
        </Button>

        <MenuTrigger>
          <Button>
            <AddOutlined />
            {t("New Material")}
          </Button>
          <Menu placement="bottom end">
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add Material")}
              onAction={() => navigate("/suppliers-and-materials/new-raw-material")}
            />
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add Packaging Material")}
              onAction={() => navigate("/suppliers-and-materials/new-packaging")}
            />
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add Own Product")}
              onAction={() => navigate("/suppliers-and-materials/new-prechain-product")}
            />
          </Menu>
        </MenuTrigger>
      </>
    ),
    [navigate, t],
  );

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const allMaterials = useMemo(
    () => [...rawMaterials, ...packagingMaterials],
    [rawMaterials, packagingMaterials],
  );

  const filteredMaterials = useMemo(() => {
    if (type === "supplier")
      return rawMaterials.filter((material) => exists(material.supplier_product));
    if (type === "packaging") return packagingMaterials;
    if (type === "own") return rawMaterials.filter((material) => exists(material.prechain_product));
    return [...rawMaterials, ...packagingMaterials];
  }, [type, rawMaterials, packagingMaterials]);

  const title = useMemo(() => {
    if (type === "own") return t("Own Products");
    if (type === "packaging") return t("Packaging");
    return t("Materials");
  }, [type, t]);

  const subtitle = useMemo(() => {
    if (type === "own")
      return (
        <>
          {filteredMaterials.length} {t("own products", { count: filteredMaterials.length })}
        </>
      );
    if (type === "packaging")
      return (
        <>
          {packagingMaterials.length}{" "}
          {t("packaging materials", { count: packagingMaterials.length })}
        </>
      );

    return (
      <>
        {filteredMaterials.length} {t("materials", { count: filteredMaterials.length })}
        {", "}
        {suppliers.length} {t("suppliers", { count: suppliers.length })}
      </>
    );
  }, [type, t, filteredMaterials, packagingMaterials.length, suppliers.length]);

  return (
    <Suspense>
      <PageContainer>
        <TopBar
          title={title}
          subtitle={subtitle}
          icon={<TokenOutlined />}
          input={filteredMaterials.length > 0 ? input : undefined}
        />
        <div className="flex-grow flex flex-col gap-4 pt-8 pb-16">
          {allMaterials.length === 0 && (
            <div className="flex-grow w-full flex flex-col items-center justify-center gap-3">
              <div className="flex flex-col items-center gap-3">
                <Label className="flex items-center gap-2">{t("No materials added yet")}</Label>
                <div className="flex items-center gap-5">{input}</div>
              </div>
            </div>
          )}
          {allMaterials.length > 0 && <MaterialDataGrid materials={filteredMaterials} />}
        </div>
      </PageContainer>
    </Suspense>
  );
};

const MaterialDataGrid = ({ materials }: { materials: PlantMaterial[] }) => {
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const { t } = useTranslation();

  const { elementaries } = useElementaries();

  const getRow = (input: PlantMaterial) => {
    if (input.supplier_product) {
      const item = input.supplier_product;
      const elementary = elementaries.find((c) => c.id === item.elementary_id);
      return {
        id: item.id,
        category: t(elementary!.name),
        productName: item.name,
        supplierCompany: item.supplier_plant?.supplier_company.name,
        plant: item.supplier_plant?.name,
        epdLinked: !!item.epd_id,
        isPrechainProduct: false,
        isPackaging: elementary?.type === "packaging",
      };
    }

    if (!input.prechain_product) {
      throw new Error("Invalid input");
    }

    const item = input.prechain_product;
    return {
      id: item.id,
      category: t(item.product.material),
      productName: item.product.name,
      supplierCompany: item.product.manufacturer.name,
      plant: item.plant_name,
      epdLinked: false,
      isPrechainProduct: true,
      isPackaging: false,
    };
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "category",
        headerName: t("Material"),
        width: 300,
        renderCell: ({ value, row: { isPackaging } }) => {
          return (
            <div className="flex items-center gap-2 h-full">
              {isPackaging ? <Inventory2Outlined /> : <TokenOutlined />}
              <Label16>{value}</Label16>
            </div>
          );
        },
      },
      {
        field: "productName",
        headerName: t("Name"),
        width: 300,
      },
      {
        field: "supplierCompany",
        headerName: t("Supplier"),
        width: 250,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "plant",
        headerName: t("Supplier plant"),
        width: 200,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "epdLinked",
        headerName: t("EPD linked"),
        width: 200,
        align: "center",
        renderCell: ({ value, row }) => (
          <div className="h-full flex items-center">
            {row.isPrechainProduct ? (
              <div className="flex items-center gap-1">
                <Commit fontSize="small" className="-translate-x-0.5" />
                <Label16>{t("Own Product")}</Label16>
              </div>
            ) : (
              <StatusIndicator
                text={value ? "EPD" : t("Secondary Data")}
                done={!!value}
                missing={!value}
              />
            )}
          </div>
        ),
      },
      {
        field: "gwpTotal",
        headerName: t("GWP"),
        description: t("Global Warming Potential for production and transport of 1 kg of material"),
        width: 150,
        renderCell: (params) => (
          <Suspense fallback={<CircularProgress size="24px" />}>
            <GwpCell
              rowId={params.row.id}
              isPrechain={params.row.isPrechainProduct}
              // For now, we only show the GWP for prechain products, or if an EPD is linked
              hide={!params.row.isPrechainProduct && !params.row.epdLinked}
            />
          </Suspense>
        ),
      },
    ];
  }, [t]);

  const { page, pageSize, handlePaginationModelChange } = useUrlPaginationSettings();

  return (
    <div className="flex flex-col gap-1">
      <DataGrid
        rows={materials
          .map(getRow)
          .sort(
            (a, b) =>
              a.category.localeCompare(b.category) || a.productName.localeCompare(b.productName),
          )}
        columns={columns}
        onRowClick={(params: GridRowParams<{ id: string; isPrechainProduct: boolean }>) => {
          if (params.row.isPrechainProduct) {
            navigate(
              getLinkWithParams(`/suppliers-and-materials/edit-prechain-product/${params.row.id}`),
            );
          } else {
            navigate(getLinkWithParams(`/suppliers-and-materials/edit/${params.row.id}`));
          }
        }}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

const GwpCell = ({
  rowId,
  isPrechain,
  hide,
}: {
  rowId: string;
  isPrechain: boolean;
  hide: boolean;
}) => {
  if (hide) return null;

  if (isPrechain) {
    return <GwpCellPrechainProduct prechainProductId={rowId} />;
  } else {
    return <GwpCellSupplierProduct materialId={rowId} />;
  }
};

const GwpCellSupplierProduct = ({ materialId }: { materialId: string }) => {
  const impacts = useMaterialImpacts(materialId);
  return <GwpBadge impacts={impacts} />;
};

const GwpCellPrechainProduct = ({ prechainProductId }: { prechainProductId: string }) => {
  const impacts = usePrechainProductImpacts(prechainProductId);
  return <GwpBadge impacts={impacts} />;
};

const GwpBadge = ({ impacts }: { impacts: Impact[] }) => {
  const gwp = impacts.find((impact) => impact.indicator_name === "GWP-total")?.value;
  return (
    <Badge color="brownLight" size="sm">
      {gwp ? formatPrecision(gwp, 3) : ""} kg CO2e
    </Badge>
  );
};
