import { CircularProgress } from "@mui/material";
import { useMemo, useState } from "react";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useUploadDocument } from "../../api/endpoints/declarations";
import { Declaration, Product } from "../../api/types";
import { Button } from "../../components/Button";
import { SidePanel } from "../../components/SidePanel";
import { Label12, Text14, Text16 } from "../../components/Typography";
import { useFilteredDisplayDocuments } from "../../lib/documents";
import { EPD_NORGE_ID, HARD_CODED_PROGRAM_OPERATION_IMAGES } from "../../lib/programOperator";
import { useAdminMode } from "../../state/admin";
import { useDeclarations } from "../../state/declarations";
import { useProductMetadata } from "../../state/productMetadata";
import { useVerifyProduct } from "../../state/products";
import { useProgramOperators } from "../../state/programOperators";
import { ViewOrDownloadDocument } from "../ViewOrDownloadDocument";
import { SelectProgramOperatorForm } from "./SelectProgramOperatorForm";

export const ProductDetailSidePanel = ({
  open,
  setOpen,
  product,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  product: Product;
}) => {
  const { data: declaration } = useDeclarations({
    productId: product.id,
    plantId: product.plant_id,
  });

  const adminMode = useAdminMode();

  const onClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  return (
    <SidePanel title={t("Documents")} open={open} onClose={onClose}>
      <div className="space-y-8">
        {product.active_jobs > 0 ? (
          <ProductDocumentsSkeleton />
        ) : (
          <>
            <ProductDocuments product={product} isAdminMode={adminMode} declaration={declaration} />
            {declaration && (
              <ViewDeclaration
                product={product}
                isAdminMode={adminMode}
                declaration={declaration}
              />
            )}
          </>
        )}
      </div>
    </SidePanel>
  );
};

const ProductDocumentsSkeleton = () => {
  const mocks = ["EPD", "Background Report", "ILCD_EPD"];

  return (
    <div>
      {mocks.map((mock) => (
        <div
          key={mock}
          className="border-neutral-300 flex items-center justify-between gap-2 min-h-12 -mx-4 px-4"
        >
          <Text16 className="font-bold">{mock}</Text16>
          <div>
            <div className="flex justify-center items-center h-full">
              <CircularProgress size="24px" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductDocuments = ({
  product,
  isAdminMode,
  declaration,
}: {
  product: Product;
  isAdminMode: boolean;
  declaration: Declaration | null;
}) => {
  const { t } = useTranslation();

  const {
    data: { productMetadataMap },
  } = useProductMetadata();

  const [activeOperatorId, setActiveOperatorId] = useState<string>(EPD_NORGE_ID);

  const { mutate: verifyProduct, isPending: verificationLoading } = useVerifyProduct();
  const onVerify = () => {
    verifyProduct({
      plantId: product.plant_id,
      productId: product.id,
      programOperatorId: activeOperatorId,
    });
  };

  const displayDocuments = useFilteredDisplayDocuments({
    documents: product.documents,
    declaration,
    isAdminMode,
    activeOperatorId,
  });

  const programOperatorAvailability = useMemo(() => {
    return productMetadataMap[product.metadata.id]?.po_availability;
  }, [product, productMetadataMap]);

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        {displayDocuments.length > 0 && (
          <div className="flex flex-col">
            {displayDocuments
              .sort((a, b) => a.document.name.localeCompare(b.document.name))
              .map((doc) => (
                <div
                  key={doc.document.name}
                  className="grid grid-cols-[1fr_auto] items-center gap-2 hover:bg-neutral-100 -mx-4 px-4"
                >
                  <Text16 className="font-bold">{doc.document.name}</Text16>
                  <ViewOrDownloadDocument doc={doc.document} />
                </div>
              ))}
          </div>
        )}
      </div>

      {!declaration && (
        <>
          {programOperatorAvailability.length > 0 && (
            <SelectProgramOperatorForm
              poWithAvailability={programOperatorAvailability}
              activeOperatorId={activeOperatorId}
              setActiveOperatorId={setActiveOperatorId}
            />
          )}
          {displayDocuments.length > 0 && (
            <Button intent="primary" isDisabled={verificationLoading} onPress={onVerify}>
              {verificationLoading && <CircularProgress size="24px" />}
              {t("Verify & publish")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const ViewDeclaration = ({
  declaration,
  product,
  isAdminMode,
}: {
  declaration: Declaration;
  product: Product;
  isAdminMode: boolean;
}) => {
  const { t } = useTranslation();
  const { programOperatorsMap } = useProgramOperators();

  const { mutate: uploadDocument } = useUploadDocument();
  const handleUpload = (files: FileList | null) => {
    const file = files?.[0];
    if (!file || !declaration) return;
    const token = prompt("Enter emidat token");
    if (!token) return;

    uploadDocument({
      plantId: product.plant_id,
      productId: product.id,
      declarationId: declaration.id,
      token,
      file,
    });
  };

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <Label12>{t("EPD Documents")}</Label12>
        <div className="flex flex-col divide-y divide-neutral-300 border-t border-b border-neutral-300">
          {declaration.result_documents.map((doc) => (
            <div
              key={doc.document.name}
              className="grid grid-cols-[1fr_auto] items-center gap-2 py-2"
            >
              <Text16 className="font-bold">{doc.document.name}</Text16>
              <ViewOrDownloadDocument doc={doc.document} />
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-4">
        <Label12>{t("Verification")}</Label12>
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-center overflow-hidden h-12 w-12 border">
            <img
              className={`w-full h-full object-contain`}
              src={HARD_CODED_PROGRAM_OPERATION_IMAGES[declaration.program_operator_id]}
            />
          </div>
          <div>
            <Text16 className="font-bold">
              {programOperatorsMap[declaration.program_operator_id].name}
            </Text16>
            <Text14>
              {product.status === "completed"
                ? t("Verified and published")
                : t("Pending verification...")}
            </Text14>
          </div>
        </div>
      </div>

      {isAdminMode && (
        <FileTrigger onSelect={handleUpload}>
          <Button intent="secondary">{t("Upload Document")}</Button>
        </FileTrigger>
      )}
    </div>
  );
};
