import { ajvResolver } from "@hookform/resolvers/ajv";
import { FactoryOutlined, LockOpenOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchPlant } from "../../api/endpoints/plants";
import { DynamicFormJSONSchema } from "../../api/types";
import { Button } from "../../components/Button";
import { Card, CardDivider } from "../../components/Card";
import { TopBar } from "../../components/TopBar";
import { useActivePlant } from "../../state/plants";
import { showSuccessToast } from "../../util/toasts";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import {
  ELECTRICITY_MIX_DEFAULT_VALUES,
  ElectricityMix,
  ElectricityMixForm,
  useElectricityMixResolver,
} from "./ElectricityMix";
import { WasteTreatment } from "./WasteTreatment";

export const ProductionGeneralSettingsPage = ({
  wasteTreatmentSchema,
}: {
  wasteTreatmentSchema: DynamicFormJSONSchema;
}) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);

  const activePlant = useActivePlant();
  const [averageMix, setAverageMix] = useState(!activePlant.electricity_mix);
  const electricityMixResolver = useElectricityMixResolver();

  const methods1 = useForm<ElectricityMixForm>({
    defaultValues: activePlant.electricity_mix || ELECTRICITY_MIX_DEFAULT_VALUES,
    resolver: (values) => (averageMix ? { values, errors: {} } : electricityMixResolver(values)),
  });

  const onChangeAverageMix = useCallback(
    (newValue: boolean) => {
      setAverageMix(newValue);
      if (!newValue && activePlant.electricity_mix) {
        methods1.reset(activePlant.electricity_mix);
      }
    },
    [methods1, activePlant],
  );

  const methods2 = useForm<Record<string, string>>({
    // @ts-expect-error TODO: Try and fix https://ajv.js.org/guide/typescript.html#utility-types-for-schemas
    resolver: ajvResolver(activePlant.waste_treatment_schema, { strict: false }),
    defaultValues: activePlant.waste_treatment,
  });

  const { mutate: patchPlant, isPending } = usePatchPlant();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const values1 = methods1.getValues();
    const values2 = methods2.getValues();

    patchPlant(
      {
        plantId: activePlant.id,
        plant: {
          ...activePlant,
          electricity_mix: averageMix ? null : { ...values1 },
          waste_treatment: values2,
        },
      },
      {
        onSuccess: () => {
          setEditing(false);
          showSuccessToast(t("Successfully saved electricity and waste treatment settings"));
        },
      },
    );
  };

  const isValid = averageMix
    ? methods2.formState.isValid
    : methods1.formState.isValid && methods2.formState.isValid;

  const wasAverageMixChanged = averageMix !== !activePlant.electricity_mix;
  const isDirty = wasAverageMixChanged || methods1.formState.isDirty || methods2.formState.isDirty;

  useWarnBeforeUnload(editing && isDirty, { withBlocker: true });

  return (
    <form onSubmit={onSubmit}>
      <TopBar
        title={t("General")}
        icon={<FactoryOutlined />}
        subtitle={t("Electricity and Waste Management settings")}
        input={
          editing ? (
            <>
              <Button
                intent="secondary"
                onPress={() => {
                  methods1.reset(activePlant.electricity_mix || ELECTRICITY_MIX_DEFAULT_VALUES);
                  methods2.reset(activePlant.waste_treatment);
                  setEditing(false);
                  setAverageMix(!activePlant.electricity_mix);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button intent="primary" type="submit" isDisabled={!isValid}>
                {t("Save data")}
                {isPending ? <CircularProgress size="24px" /> : <LockOpenOutlined />}
              </Button>
            </>
          ) : (
            <Button intent="primary" onPress={() => setEditing(true)}>
              {t("Edit data")}
            </Button>
          )
        }
      />
      <div className="flex flex-col gap-8 py-8">
        <Card>
          <div className="flex flex-col gap-6">
            <FormProvider {...methods1}>
              <ElectricityMix
                averageMix={averageMix}
                editing={editing}
                onChangeAverageMix={onChangeAverageMix}
              />
            </FormProvider>
            <CardDivider />
            <FormProvider {...methods2}>
              <WasteTreatment schema={wasteTreatmentSchema} editing={editing} />
            </FormProvider>
          </div>
        </Card>
      </div>
    </form>
  );
};
