import { useCallback } from "react";
import { Supplier, SupplierPlant } from "../types";
import { useApi } from "../useApi";

export const useGetSuppliers = () => {
  const api = useApi<Supplier[]>({ method: "GET" });

  return useCallback(
    ({ plantId }: { plantId: string }) =>
      api({
        url: `/v1/plants/${plantId}/suppliers`,
      }),
    [api],
  );
};

export const usePostSuppliers = () => {
  const api = useApi<Supplier>({ method: "POST" });

  return useCallback(
    ({ plantId, supplier }: { plantId: string; supplier: Pick<Supplier, "name"> }) =>
      api({
        url: `/v1/plants/${plantId}/suppliers`,
        data: supplier,
      }),
    [api],
  );
};

export const useGetSupplierPlants = () => {
  const api = useApi<SupplierPlant[]>({ method: "GET" });

  return useCallback(
    ({ plantId, supplierId }: { plantId: string; supplierId: string }) =>
      api({
        url: `/v1/plants/${plantId}/suppliers/${supplierId}/plants`,
      }),
    [api],
  );
};

export const usePostSupplierPlants = () => {
  const api = useApi<SupplierPlant>({ method: "POST" });

  return useCallback(
    ({
      plantId,
      supplierId,
      supplierPlant,
    }: {
      plantId: string;
      supplierId: string;
      supplierPlant: Omit<SupplierPlant, "id" | "supplier_company_id" | "supplier_company">;
    }) =>
      api({
        url: `/v1/plants/${plantId}/suppliers/${supplierId}/plants`,
        data: supplierPlant,
      }),
    [api],
  );
};
