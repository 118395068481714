import { ProductionProcess } from "../../api/types";
import { EmptyProductionProcessList } from "./EmptyProductionProcessList";
import { ProductionProcessSummaryCard } from "./ProductionProcessSummaryCard";

export const ProductionProcessList = ({ processes }: { processes: ProductionProcess[] }) => {
  return (
    <>
      {processes.length === 0 && <EmptyProductionProcessList />}
      <div className="space-y-6">
        {processes
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((process) => (
            <ProductionProcessSummaryCard key={process.id} process={process} />
          ))}
      </div>
    </>
  );
};
