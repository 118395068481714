import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetDeclaration } from "../api/endpoints/declarations";

export const useDeclarations = ({ productId, plantId }: { productId: string; plantId: string }) => {
  const getDeclarations = useGetDeclaration();

  return useSuspenseQuery({
    queryFn: () => getDeclarations({ productId, plantId }),
    queryKey: [plantId, productId, "declarations"],
  });
};
