import { ArrowDropDownOutlined, ArrowDropUpOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Impact } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Label14, Text14 } from "../../components/Typography";
import { formatPrecision } from "../../util/format";

const useImpactFactorLabels = (): Record<string, string> => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      "GWP-total": t("Global Warming Potential"),
      ODP: t("Ozone Depletion"),
      AP: t("Water & Soil Acidification"),
      "EP-freshwater": t("Algae Bloom"),
      POCP: t("Smog Formation"),
      ADPE: t("Metals & Minerals Depletion"),
      ADPF: t("Fossil Fuels Depletion"),
      WDP: t("Water Depletion"),

      // unclear whether we need these:
      PM: t("Particulate Matter"),
      PERT: t("Photochemical Ozone Creation Potential"),
      FW: t("Freshwater Ecotoxicity"),
    };
  }, [t]);
};

export const ImpactFactors = ({
  impacts,
  impacts2,
}: {
  impacts: Impact[];
  impacts2?: Impact[];
}) => {
  return (
    <div className="grid gap-6">
      <ImpactFactorsItems impacts={impacts} impacts2={impacts2} />
    </div>
  );
};

export const ImpactFactors2Columns = ({
  impacts,
  impacts2,
}: {
  impacts: Impact[];
  impacts2?: Impact[];
}) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <ImpactFactorsItems impacts={impacts} impacts2={impacts2} />
    </div>
  );
};

const ImpactFactorsItems = ({ impacts, impacts2 }: { impacts: Impact[]; impacts2?: Impact[] }) => {
  const labels = useImpactFactorLabels();
  return (
    <>
      {impacts.map((impact, index) => {
        const impact2 = impacts2?.[index];
        const diff = impact2 ? impact.value - impact2.value : 0;
        const diffPercentage = impact2 ? (diff / impact2.value) * 100 : 0;
        const unitSplit = impact.unit.split(" ");
        const shortUnit = unitSplit[0].replace(",", "");
        const restUnit = unitSplit.slice(1).join(" ");
        const labelUnit = restUnit.length > 0 ? restUnit : impact.unit;

        return (
          <div className="flex items-center gap-6" key={impact.indicator_name}>
            {impacts2 && (
              <Badge color="brown" size="sm" shrunkIcon>
                {diffPercentage > 0 ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
                {Math.round(diffPercentage)}%
              </Badge>
            )}

            <div className="flex flex-col gap-1">
              <Text14>
                {labels[impact.indicator_name]} ({impact.indicator_name}) in {labelUnit}
              </Text14>
              <Label14 className="flex gap-2">
                {formatPrecision(impact.value, 4)} {shortUnit}
                {impact2 && (
                  <span className="text-neutral-400">
                    | {formatPrecision(impact2.value, 4)} {shortUnit}
                  </span>
                )}
              </Label14>
            </div>
          </div>
        );
      })}
    </>
  );
};
