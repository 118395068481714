import { AccountTreeOutlined, Clear } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Suspense, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { usePostProductionProcess } from "../../api/endpoints/production-processes";
import { ProductionProcessTemplate } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { Link } from "../../components/Link";
import { PageContainer } from "../../components/PageContainer";
import { TopBar } from "../../components/TopBar";
import { Label16, Text16 } from "../../components/Typography";
import { useElementaries } from "../../state/elementaries";
import { useActivePlant } from "../../state/plants";
import { useProductionProcessTemplates } from "../../state/productionProcessTemplates";
import { RenderedFlow } from "../product-detail/RenderedFlow";

const useSubmitProductionProcess = ({
  selectedProcessTemplateId,
}: {
  selectedProcessTemplateId: string | null;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: templates } = useProductionProcessTemplates();
  const { mutate: postProductionProcess, isPending } = usePostProductionProcess();

  const onSubmit = useCallback(() => {
    if (!selectedProcessTemplateId) return;

    postProductionProcess(
      {
        productionProcessTemplateId: selectedProcessTemplateId,
        name: t(templates.find((t) => t.id === selectedProcessTemplateId)!.name),
      },
      {
        onSuccess: (process) => navigate(`/production/processes/${process.id}/edit`),
      },
    );
  }, [navigate, postProductionProcess, selectedProcessTemplateId, t, templates]);

  return {
    isPending,
    onSubmit,
  };
};

const TemplatesList = ({
  selectedProcessTemplateId,
  setSelectedProcessTemplateId,
}: {
  selectedProcessTemplateId: string | null;
  setSelectedProcessTemplateId: (id: string | null) => void;
}) => {
  const { data: productionProcessTemplates } = useProductionProcessTemplates();
  const { elementaries } = useElementaries();
  const activePlant = useActivePlant();

  const canProduceElementaries = useMemo(() => {
    return new Set(
      elementaries
        .filter(
          (e) =>
            e.product_category_id && activePlant.product_categories.includes(e.product_category_id),
        )
        .map((e) => e.id),
    );
  }, [activePlant, elementaries]);

  return (
    <>
      {productionProcessTemplates
        .filter((process) =>
          process.elementary_ids.some((elementaryId) => canProduceElementaries.has(elementaryId)),
        )
        .map((process) => (
          <ProductionProcessItem
            key={process.name}
            isActive={selectedProcessTemplateId === process.id}
            onSelectItem={setSelectedProcessTemplateId}
            item={process}
          />
        ))}
    </>
  );
};

export const NewProductionProcess = () => {
  const { t } = useTranslation();
  const [selectedProcessTemplateId, setSelectedProcessTemplateId] = useState<string | null>(null);

  const navigate = useNavigate();
  const { isPending, onSubmit } = useSubmitProductionProcess({ selectedProcessTemplateId });

  return (
    <PageContainer
      $as="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <TopBar
        icon={<AccountTreeOutlined />}
        title={t("New Production Process")}
        input={
          <Button intent="tertiaryFlat" size="small" onPress={() => navigate("/production")}>
            {t("Cancel process")}
            <Clear />
          </Button>
        }
      />
      <div className="flex flex-col gap-6 pt-8 pb-32">
        <Suspense>
          <TemplatesList
            selectedProcessTemplateId={selectedProcessTemplateId}
            setSelectedProcessTemplateId={setSelectedProcessTemplateId}
          />
        </Suspense>
        <div className="text-center">
          <Text16>
            {t("Can't find your Production Process? Add further Product Categories in your")}{" "}
            <Link intent="link" href="/manage-plants/edit">
              {t("Plant Settings")}
            </Link>
            .
          </Text16>
        </div>
        <FormActions
          onPrev={() => {
            navigate("/production");
          }}
          nextDisabled={!selectedProcessTemplateId}
          nextLoading={isPending}
        />
      </div>
    </PageContainer>
  );
};

const FormActions = ({
  onPrev,
  onNext,
  nextDisabled,
  nextLoading,
}: {
  onPrev?: () => void;
  onNext?: () => void;
  nextDisabled?: boolean;
  nextLoading?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className="fixed bottom-10 left-1/2 -translate-x-1/2 flex items-center justify-center gap-4">
      <Button type="button" intent="secondary" onPress={onPrev}>
        {t("Back")}
      </Button>
      <Button onPress={onNext} type="submit" isDisabled={nextDisabled || nextLoading}>
        {t("Next")}
        {nextLoading && <CircularProgress size="20px" />}
      </Button>
    </div>
  );
};

const ItemContainer = tw("div")<{ $active?: boolean; $disabled?: boolean }>`
  bg-white
  transition-colors
  rounded-2xl
  border
  px-6
  py-4
  space-y-3
  ${({ $active }) =>
    $active
      ? "border-builtgreen hover:border-builtgreen shadow-e-md hover:shadow-e-md"
      : "hover:border-builtgreen hover:shadow-e-sm"}
  ${({ $disabled }) =>
    $disabled
      ? "opacity-70 cursor-not-allowed hover:border-neutral-200 hover:shadow-none"
      : "cursor-pointer"}
`;

const ProductionProcessItem = ({
  item,
  isActive,
  isDisabled,
  onSelectItem,
}: {
  item: ProductionProcessTemplate;
  isActive: boolean;
  isDisabled?: boolean;
  onSelectItem: (id: string | null) => void;
}) => {
  const { elementariesMap } = useElementaries();
  const { t } = useTranslation();
  return (
    <ItemContainer
      $active={isActive}
      $disabled={isDisabled}
      onClick={() => onSelectItem(isDisabled ? null : item.id)}
    >
      <div className="flex justify-between">
        <Label16>{t(item.name)}</Label16>
        <div className="flex items-end justify-end flex-wrap gap-2">
          {item.elementary_ids.map((id) => (
            <Badge key={id}>{t(elementariesMap[id]?.name)}</Badge>
          ))}
        </div>
      </div>
      <hr className="border-neutral-200 -mx-6" />
      <RenderedFlow flow={item} />
    </ItemContainer>
  );
};
