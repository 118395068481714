import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  useDeleteRemoveUser,
  useGetMyProfile,
  useGetProfiles,
  usePostInviteUser,
  usePostTransferOwnership,
} from "../api/endpoints/profiles";

export const useProfiles = () => {
  const getProfiles = useGetProfiles();

  return useSuspenseQuery({
    queryFn: () => getProfiles(),
    queryKey: ["profiles"],
  });
};

export const useMyProfile = () => {
  const getMyProfile = useGetMyProfile();

  return useSuspenseQuery({
    queryFn: getMyProfile,
    queryKey: ["my-profile"],
  });
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const postInviteUser = usePostInviteUser();

  return useMutation({
    mutationFn: (email: string) => postInviteUser({ email }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profiles"] });
    },
  });
};

export const useRemoveUser = () => {
  const queryClient = useQueryClient();
  const removeUser = useDeleteRemoveUser();

  return useMutation({
    mutationFn: (profileId: string) => removeUser({ profileId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profiles"] });
    },
  });
};

export const useTransferOwnership = () => {
  const queryClient = useQueryClient();
  const transferOwnership = usePostTransferOwnership();

  return useMutation({
    mutationFn: (profileId: string) => transferOwnership({ profileId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profiles"] });
      queryClient.invalidateQueries({ queryKey: ["my-profile"] });
    },
  });
};
