import { cva } from "class-variance-authority";
import { ReactNode } from "react";
import { Button } from "./Button";
import { Label14 } from "./Typography";

const containerStyles = cva([
  "bg-neutral-100",
  "rounded-lg",
  "flex",
  "items-center",
  "[&>button[data-active=false]>p]:!text-neutral-400",
  "[&>button[data-active=false]>p]:grayscale",
])();

export const TabSwitch = ({
  activeTab,
  setActiveTab,
  options,
}: {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  options: { label: ReactNode; value: string }[];
}) => {
  return (
    <div className={containerStyles}>
      {options.map(({ label, value }) => (
        <Button
          intent="secondary"
          data-active={value === activeTab}
          key={value}
          onPress={() => setActiveTab(value)}
        >
          <Label14>{label}</Label14>
        </Button>
      ))}
    </div>
  );
};
