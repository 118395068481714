import { useTranslation } from "react-i18next";
import { ProductionProcess } from "../../api/types";
import { Label14 } from "../../components/Typography";

const ProcessStep = ({
  flow,
  step,
}: {
  flow: ProductionProcess;
  step: ProductionProcess["steps"][number];
}) => {
  const children = flow.steps.filter((s) => s.parent_id === step.id);
  const { t } = useTranslation();

  return (
    <div className="flex flex-row-reverse">
      <div className="flex items-center">
        <div className="rounded-lg border border-yellow-300 bg-white h-24 w-44 py-1 px-3 flex items-center justify-center text-center shadow-e-md">
          <Label14>{t(step.name)}</Label14>
        </div>
        <div className="w-32 h-6 bg-concrete"></div>
      </div>
      {children.length > 0 && (
        <div className="flex flex-col items-end gap-2 relative">
          {children.map((child) => (
            <div key={child.id} className="flex flex-col items-center gap-5">
              <ProcessStep flow={flow} step={child} />
            </div>
          ))}
          {children.length > 1 && (
            <div className="absolute bg-concrete w-2 right-0 h-3/4 top-1/2 -translate-y-1/2 rounded-xl" />
          )}
        </div>
      )}
    </div>
  );
};

export const ProcessFlowModel = ({ process }: { process: ProductionProcess }) => {
  const rootStep = process.steps.find((step) => step.parent_id === null);

  return (
    <div className="w-full overflow-hidden p-5 h-full">
      <div className="overflow-auto p-5 w-full h-full flex items-center">
        <div className="flex items-center">
          {rootStep && <ProcessStep flow={process} step={rootStep} />}
          <div className="text-6xl">📦</div>
        </div>
      </div>
    </div>
  );
};
