import { useParams } from "react-router";
import { PrechainProductForm } from "../page-components/suppliers-and-materials/PrechainProductForm";
import { usePrechainProduct } from "../state/prechainProducts";

export const EditPrechainProduct = () => {
  const { id } = useParams();
  const prechainProduct = usePrechainProduct(id ?? "");

  if (!id || !prechainProduct) return null;

  return <PrechainProductForm prechainProduct={prechainProduct} />;
};
