export const formatPrecision = (value: number, precision: number): string => {
  if (Math.abs(value) < 1 / 10 ** precision) {
    return value.toExponential(precision);
  } else {
    return value.toPrecision(precision);
  }
};

export const formatUnit = (unit: string): string => {
  const mapping: Record<string, string> = {
    KG: "kg",
    MJ: "MJ",
    KWH: "kWh",
    T: "t",
    M: "m",
    "M^2": "m²",
    M2: "m²",
    "MM^2": "mm²",
    MM2: "mm²",
    M3: "m³",
    "M^3": "m³",
    CO2: "CO₂",
    "CO2-EQ": "CO2e",
    PIECE: "piece",
    UNIT: "piece",
  };

  const splitted = unit.split(" ");
  if (splitted.length > 1) {
    return splitted.map((x) => formatUnit(x)).join(" ");
  } else {
    return mapping[unit.toUpperCase()] ?? unit;
  }
};

export const formatDate = (date: Date, locale: Intl.Locale): string => {
  return new Date(date).toLocaleDateString(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};
