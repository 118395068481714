import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  useDeletePrechainProduct,
  useGetPrechainProduct,
  useGetPrechainProductImpacts,
} from "../api/endpoints/prechain-products";
import { PrechainProduct } from "../api/types";
import { useActivePlant } from "./plants";

export const usePrechainProduct = (id: string) => {
  const activePlant = useActivePlant();

  const getPrechainProduct = useGetPrechainProduct();

  const [prechainProduct, setPrechainProduct] = useState<PrechainProduct | null>(null);

  useEffect(() => {
    if (!activePlant) return;

    getPrechainProduct({
      plantId: activePlant.id,
      prechainProductId: id,
    }).then(setPrechainProduct);
  }, [activePlant, getPrechainProduct, id]);

  return prechainProduct;
};

export const usePrechainProductImpacts = (id: string) => {
  const activePlant = useActivePlant();

  const getPrechainProductImpacts = useGetPrechainProductImpacts();

  const { data } = useSuspenseQuery({
    queryKey: ["prechainProductImpacts", id],
    queryFn: () =>
      getPrechainProductImpacts({
        plantId: activePlant.id,
        prechainProductId: id,
      }),
    gcTime: 0,
  });

  return data;
};

export const useDeletePrechainProductById = () => {
  const activePlant = useActivePlant();

  const deletePrechainProduct = useDeletePrechainProduct();

  return (id: string) => {
    if (!activePlant) return;

    return deletePrechainProduct({
      plantId: activePlant.id,
      prechainProductId: id,
    });
  };
};
