import { SearchOutlined } from "@mui/icons-material";
import { compareAsc, compareDesc } from "date-fns";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { Product } from "../../api/types";
import { Heading4, Text16 } from "../../components/Typography";
import { ProductCard } from "./ProductCard";
import { useProductSorting } from "./useProductSorting";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6
`;

const statusOrder: Record<Product["status"], number> = {
  incomplete: 0,
  draft: 1,
  "under-review": 2,
  completed: 3,
};

const compareCreatedAt = (a: Product, b: Product, direction: "asc" | "desc") => {
  return direction === "desc"
    ? compareDesc(new Date(a.created_at), new Date(b.created_at))
    : compareAsc(new Date(a.created_at), new Date(b.created_at));
};

const compareGwpTotal = (a: Product, b: Product, direction: "asc" | "desc") => {
  const aGwp =
    a.lca_results?.impact_summary?.a1_a3.find((i) => i.indicator_name === "GWP-total")?.value || 0;
  const bGwp =
    b.lca_results?.impact_summary?.a1_a3.find((i) => i.indicator_name === "GWP-total")?.value || 0;
  return direction === "desc" ? bGwp - aGwp : aGwp - bGwp;
};

const useCompareProducts = () => {
  const { t } = useTranslation();

  const compareProducts = useCallback(
    (a: Product, b: Product, sort: ReturnType<typeof useProductSorting>["sort"]) => {
      switch (sort.by) {
        case "created_at":
          return compareCreatedAt(a, b, sort.direction);

        case "name":
          return sort.direction === "desc"
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);

        case "gwpTotal":
          return compareGwpTotal(a, b, sort.direction);

        case "status":
          return statusOrder[b.status] - statusOrder[a.status];

        case "material":
          return t(a.material).localeCompare(t(b.material)) * (sort.direction === "desc" ? -1 : 1);

        default:
          return 0;
      }
    },
    [t],
  );

  return compareProducts;
};

export const ProductsTileView = memo(function ProductsTileViewComponent({
  products,
  searchInput,
  isSelectMode,
  selectedProducts,
  toggleSelect,
  sort,
}: {
  products: Product[];
  searchInput: string;
  isSelectMode: boolean;
  selectedProducts: Set<Product>;
  toggleSelect: (product: Product) => void;
  sort: ReturnType<typeof useProductSorting>["sort"];
}) {
  const compareProducts = useCompareProducts();
  const sortedProducts = useMemo(
    () => [...products].sort((a, b) => compareProducts(a, b, sort)),
    [products, sort, compareProducts],
  );

  const filteredProducts = useMemo(() => {
    return sortedProducts.filter((product) =>
      product.name.toLowerCase().includes(searchInput.toLowerCase()),
    );
  }, [sortedProducts, searchInput]);

  if (filteredProducts.length === 0) {
    return <EmptyResults />;
  }

  return (
    <Content>
      {filteredProducts.map((item) => (
        <ProductCard
          key={item.id}
          item={item}
          isSelectMode={isSelectMode}
          isSelected={selectedProducts.has(item)}
          onToggleSelect={toggleSelect}
        />
      ))}
    </Content>
  );
});

const EmptyResults = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full py-20 gap-2">
      <SearchOutlined className="text-neutral-500" fontSize="large" />
      <div className="flex flex-col items-center gap-2">
        <Heading4>{t("No results found")}</Heading4>
        <Text16 className="text-neutral-500">
          {t("Try adjusting your search or filter criteria")}
        </Text16>
      </div>
    </div>
  );
};
