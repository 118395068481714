import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Supplier, SupplierPlant } from "../../api/types";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { useCountryOptions } from "../../util/useCountryOptions";
import { TransportationForm } from "./TransportationForm";

export const SupplyRoute = ({
  supplierPlants,
  suppliers,
  supplierPlantExists,
}: {
  supplierPlants: SupplierPlant[];
  suppliers: Supplier[];
  supplierPlantExists: boolean;
}) => {
  const { t } = useTranslation();
  const countryOptions = useCountryOptions();
  const plantOptions = useMemo(() => {
    return supplierPlants.map((plant) => ({
      label: plant.name,
      id: plant.name,
    }));
  }, [supplierPlants]);

  const supplierOptions = useMemo(() => {
    const supplierNames = suppliers.map((supplier) => supplier.name);
    const uniqueSupplierNames = [...new Set(supplierNames)];

    return uniqueSupplierNames.map((name) => ({
      label: name,
      id: name,
    }));
  }, [suppliers]);

  return (
    <div className="flex flex-col gap-6">
      <ComboBoxFieldConnected
        name="supplierCompanyName"
        isRequired
        options={supplierOptions}
        label={t("Supplier")}
        creatable
      />
      <div className="grid items-center grid-cols-2 gap-6">
        <ComboBoxFieldConnected
          name="supplierPlantName"
          isRequired
          options={plantOptions}
          label={t("Supplier plant")}
          creatable
        />
        <ComboBoxFieldConnected
          name="supplierPlantCountry"
          isRequired
          options={countryOptions}
          label={t("Plant location")}
          isDisabled={supplierPlantExists}
        />
      </div>
      <TransportationForm />
    </div>
  );
};
