import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Label } from "../../components/Typography";
import { useDownloadProductExcelExport } from "../../state/products";

export const DataExport = () => {
  const { t } = useTranslation();

  const { mutate: download, isPending: loading } = useDownloadProductExcelExport();

  return (
    <div className="flex-grow flex flex-col gap-4 py-4">
      <Label>{t("Export all products from all plants?")}</Label>
      <div>
        <Button intent="secondary" onPress={() => download()}>
          {loading && <CircularProgress size="24px" />}
          {t("Export Data to Excel")}
        </Button>
      </div>
    </div>
  );
};
