import { useSearchParams } from "react-router";

export const useUrlPaginationSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get("page")) || 0;
  const pageSize = Number(searchParams.get("pageSize")) || 50;

  const handlePaginationModelChange = (model: { page: number; pageSize: number }) => {
    setSearchParams(
      (prev) => {
        prev.set("page", model.page.toString());
        prev.set("pageSize", model.pageSize.toString());
        return prev;
      },
      { replace: true },
    );
  };

  return { page, pageSize, handlePaginationModelChange };
};
