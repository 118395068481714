import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TabSwitch } from "../components/TabSwitch";

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  const options = useMemo(
    () => [
      { label: "🇬🇧 EN", value: "en" },
      { label: "🇩🇪 DE", value: "de" },
    ],
    [],
  );

  return (
    <TabSwitch
      activeTab={i18n.language}
      setActiveTab={(lang) => i18n.changeLanguage(lang)}
      options={options}
    />
  );
};
