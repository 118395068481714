import { DeleteOutlined, WaterDropOutlined } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HazardousShare } from "../../api/types";
import { Button } from "../../components/Button";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { Text16 } from "../../components/Typography";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { HARDCODED_CAS_NUMBERS } from "../../state/HARD_CODED";
import { FormFields } from "./types";

const CAS_OPTIONS = HARDCODED_CAS_NUMBERS.map((casNumber) => ({
  label: casNumber,
  id: casNumber,
}));

export const HazardousSubstancesForm = () => {
  const { t } = useTranslation();
  const methods = useFormContext<{ hazardousSubstances: HazardousShare[] }>();

  const subtances = methods.watch("hazardousSubstances");

  return (
    <div className="space-y-6">
      <div className="space-y-3">
        <Text16>{t("Is this material hazardous?")}</Text16>
        {subtances.map((_, index, array) => (
          <div key={index} className="flex flex-col items-start">
            <div className="grid grid-cols-[1fr_1fr_auto] items-start gap-6 w-full">
              <ComboBoxFieldConnected<FormFields>
                name={`hazardousSubstances.${index}.cas_number`}
                isRequired
                options={CAS_OPTIONS.filter(
                  (opt) =>
                    !array.some(
                      (item, itemIndex) => itemIndex !== index && item.cas_number === opt.id,
                    ),
                )}
                label={t("CAS Number")}
              />
              <NumberFieldConnected<FormFields>
                name={`hazardousSubstances.${index}.percentage`}
                rules={{
                  validate: (value) => {
                    if (value === 0) {
                      return t("Must be greater than 0");
                    }
                  },
                }}
                isRequired
                minValue={0}
                maxValue={100}
                label={t("Percentage of mass")}
                inputProps={{ addonRight: "%" }}
              />
              <div className="self-end">
                <TooltipTrigger>
                  <Button
                    intent="tertiaryFlat"
                    size="tiny"
                    onPress={() => {
                      const newArray = array.filter((_, itemIndex) => itemIndex !== index);
                      methods.setValue("hazardousSubstances", newArray);
                    }}
                  >
                    <DeleteOutlined fontSize="small" />
                  </Button>
                  <Tooltip>{t("Delete")}</Tooltip>
                </TooltipTrigger>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div>
        <Button
          intent="secondary"
          type="button"
          onPress={() => {
            const newCasNumber = CAS_OPTIONS.find(
              (opt) => !subtances.some((x) => x.cas_number === opt.id),
            );
            if (newCasNumber) {
              methods.setValue("hazardousSubstances", [
                ...subtances,
                {
                  cas_number: newCasNumber.id,
                  percentage: 0,
                },
              ]);
            }
          }}
        >
          <WaterDropOutlined />
          {subtances.length > 0 ? t("Add further hazardous content") : t("Add hazardous content")}
        </Button>
      </div>
    </div>
  );
};
