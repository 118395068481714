import { ArrowDropDownOutlined, ArrowDropUpOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { Link as LinkPrimitive } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Impact, Product } from "../../api/types";
import { Badge } from "../../components/Badge";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Heading3, Heading4, Text14, Text16 } from "../../components/Typography";
import { exists } from "../../util/commonUtil";
import { formatUnit } from "../../util/format";
import { ProductImage } from "../product-detail/ProductImage";

const getNameClasses = (name: string) => {
  const hasNoSpaces = !name.includes(" ");
  const isLong = name.length > 30;

  return `hyphens-auto ${hasNoSpaces && isLong ? "break-all" : ""}`;
};

const GWPTotal = ({
  indicator,
  netIndicator,
  alignment,
}: {
  indicator?: Impact;
  netIndicator?: Impact;
  alignment: "left" | "right";
}) => {
  const { t } = useTranslation();

  const showNetIndicator =
    !!netIndicator && exists(netIndicator.value) && indicator?.value !== netIndicator?.value;

  return (
    <div className={`flex flex-col ${alignment === "left" ? "items-start" : "items-end"}`}>
      <Text14 className="flex items-center gap-1">
        <span className={alignment === "left" ? "order-first" : "order-last"}>
          {t("GWP Total")}
        </span>
      </Text14>
      <Heading4
        className={`flex items-center gap-2 whitespace-nowrap ${alignment === "right" ? "flex-row-reverse" : ""}`}
      >
        {indicator?.value.toFixed(2)} {formatUnit(indicator?.unit ?? "")}
        {showNetIndicator && (
          <>
            <Text14 className="self-end text-neutral-500">|</Text14>
            <Text14 className="self-end text-neutral-500">
              {netIndicator.value.toFixed(2)} {formatUnit(netIndicator.unit)} net
            </Text14>
            <div>
              <InfoTooltip>
                {t(
                  "The gross value includes the impact from all manufacturing activities. Gross values are more commonly used in Northern Europe. The net value excludes the impact from the incineration of waste-derived fuels, and is more common in Central Europe and Germany.",
                )}
              </InfoTooltip>
            </div>
          </>
        )}
      </Heading4>
    </div>
  );
};

const MiddleSection = ({
  product1,
  product2,
  mainDiff,
}: {
  product1: Product;
  product2: Product;
  mainDiff: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <Badge color="brown" shrunkIcon>
        {mainDiff > 0 ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />} {Math.abs(mainDiff)}%
      </Badge>
      <Text16 className="text-center">
        <strong className={getNameClasses(product1.name)}>{product1.name}</strong>
        <br />
        {t("has a")}{" "}
        <strong>
          {Math.abs(mainDiff)}% {mainDiff > 0 ? t("higher") : t("lower")}
        </strong>{" "}
        {t("GWP-total than")}
        <br />
        <strong className={getNameClasses(product2.name)}>{product2.name}</strong>
      </Text16>
    </div>
  );
};
const useIndicatorByName = (product: Product, name: string) => {
  return useMemo(
    () => product.lca_results?.impact_summary.a1_a3.find((i) => i.indicator_name === name),
    [product.lca_results?.impact_summary.a1_a3, name],
  );
};

export const ProductComparisonSummary = ({
  product1,
  product2,
}: {
  product1: Product;
  product2: Product;
}) => {
  const { t } = useTranslation();
  const gwp1 = useIndicatorByName(product1, "GWP-total");
  const gwp1Net = useIndicatorByName(product1, "GWP-total-net");
  const gwp2 = useIndicatorByName(product2, "GWP-total");
  const gwp2Net = useIndicatorByName(product2, "GWP-total-net");

  const mainDiff = useMemo(() => {
    const value1 = gwp1?.value ?? 0;
    const value2 = gwp2?.value ?? 0;
    const denominator = value1 > value2 ? value2 || 1 : value1 || 1;
    const percentageDiff = ((value1 - value2) / denominator) * 100;

    return Math.round(percentageDiff);
  }, [gwp1?.value, gwp2?.value]);

  return (
    <div className="grid grid-cols-[2fr_1fr_2fr] gap-4 bg-concrete bg-texture p-8">
      <div className="grid grid-cols-[auto_1fr] gap-4">
        <div className="w-[200px] h-[200px]">
          <ProductImage product={product1} disableUpload />
        </div>
        <div className="flex flex-col justify-between gap-2 h-full">
          <div className="flex flex-col gap-2">
            <LinkPrimitive
              href={`/products/${product1.id}`}
              className="no-underline! hover:underline"
            >
              <Heading3 className={getNameClasses(product1.name)}>{product1.name}</Heading3>
            </LinkPrimitive>
            <Text14>
              <Badge color="white">{t(product1?.material)}</Badge>
            </Text14>
          </div>
          <GWPTotal indicator={gwp1} netIndicator={gwp1Net} alignment="left" />
        </div>
      </div>
      <MiddleSection product1={product1} product2={product2} mainDiff={mainDiff} />
      <div className="grid grid-cols-[1fr_auto] gap-4 text-right">
        <div className="flex flex-col justify-between gap-2 h-full">
          <div className="flex flex-col gap-2 items-end">
            <LinkPrimitive
              href={`/products/${product2.id}`}
              className="no-underline! hover:underline"
            >
              <Heading3 className={getNameClasses(product2.name)}>{product2.name}</Heading3>
            </LinkPrimitive>
            <Text14>
              <Badge color="white">{t(product2?.material)}</Badge>
            </Text14>
          </div>
          <div className="flex flex-col items-end">
            <GWPTotal indicator={gwp2} netIndicator={gwp2Net} alignment="right" />
          </div>
        </div>
        <div className="w-[200px] h-[200px]">
          <ProductImage product={product2} disableUpload />
        </div>
      </div>
    </div>
  );
};
