import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { Link } from "react-router";
import type { Product } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Heading4, Text16 } from "../../components/Typography";
import { formatUnit } from "../../util/format";
import { ProductImage } from "../product-detail/ProductImage";
import { ProductStatusIndicator } from "./ProductStatusIndicator";
import { useGetProductLink } from "./useProductLink";

import { cva } from "class-variance-authority";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const containerStyles = cva(
  [
    "p-2",
    "group",
    "relative",
    "w-full",
    "shadow-e-xs hover:shadow-e-sm active:shadow-e-xs",
    "translate-y-0 hover:translate-y-[-1px] active:translate-y-0",
    "transition-all",
    "[&_img]:transition-opacity",
    "[&_img]:hover:opacity-80",
    "cursor-pointer",
    "flex",
    "flex-col",
    "justify-between",
    "gap-3",
    "bg-white",
    "overflow-hidden",
  ],
  {
    variants: {
      isSelectMode: {
        true: "",
        false: "border border-neutral-300 hover:border-energyyellow",
      },
      isSelected: {
        true: "border border-builtgreen",
        false: "border border-neutral-300",
      },
    },
    defaultVariants: {
      isSelectMode: false,
      isSelected: false,
    },
  },
);

export const ProductCard = memo(function ProductCardComponent({
  item,
  isSelected,
  isSelectMode,
  onToggleSelect,
}: {
  item: Product;
  isSelected?: boolean;
  isSelectMode?: boolean;
  onToggleSelect: (product: Product) => void;
}) {
  const { t } = useTranslation();
  const getProductLink = useGetProductLink();
  const gwpTotal = item.lca_results?.impact_summary?.a1_a3.find(
    (i) => i.indicator_name === "GWP-total",
  );

  return (
    <Link
      to={isSelectMode ? "" : getProductLink({ product: item })}
      // viewTransition
      className={containerStyles({
        isSelectMode,
        isSelected,
      })}
      onClick={isSelectMode ? () => onToggleSelect(item) : undefined}
      style={{
        viewTransitionName: `product-${item.id}`,
      }}
    >
      {isSelectMode && (
        <div className="absolute top-2 left-2 z-20 bg-white px-2 py-1.5">
          {isSelected ? (
            <CheckCircle className="text-steelblue" fontSize="small" />
          ) : (
            <CircleOutlined className="text-neutral-300" fontSize="small" />
          )}
        </div>
      )}
      <ProductImage product={item} disableUpload />
      <div className={item.status === "incomplete" ? "opacity-50" : ""}>
        <Heading4 className="truncate">{item.name}</Heading4>
        <Text16 className="text-neutral-500">{t(item.material)}</Text16>
      </div>
      <div className="flex items-center justify-between gap-2 h-6">
        <ProductStatusIndicator status={item.status} truncate />
        {gwpTotal && (
          <Badge size="sm" color="brownLight">
            {gwpTotal.value.toPrecision(4)} {formatUnit(gwpTotal.unit)}
          </Badge>
        )}
      </div>
    </Link>
  );
});
