import { memo, Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Product } from "../api/types";
import { Loading } from "../components/Loading";
import { PageContainer } from "../components/PageContainer";
import { ProductDetailPageAnalyse } from "../page-components/product-detail/ProductDetailPageAnalyse";
import { ProductDetailPagePassport } from "../page-components/product-detail/ProductDetailPagePassport";
import { ProductDetailSidePanel } from "../page-components/product-detail/ProductDetailSidePanel";
import { ProductDetailTopBar } from "../page-components/product-detail/ProductDetailTopBar";
import { HARDCODED_ELEMENTARIES_WITHOUT_RECIPE } from "../state/HARD_CODED";
import { useProduct, useProductImpactScale, useUpdateProductLca } from "../state/products";
import { useGetLinkWithParams } from "../url/useGetLinkWithParams";
import { showErrorToast } from "../util/toasts";

export const ProductDetailPage = () => {
  return (
    <Suspense fallback={<Loading lazy />}>
      <ProductDetailPageContent />
    </Suspense>
  );
};

const ProductDetailPageContentMain = ({ id }: { id: string }) => {
  const { data: product, refetch } = useProduct(id);

  useEffect(() => {
    if (product.active_jobs > 0) {
      const interval = setInterval(() => {
        refetch();
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [product, refetch]);

  return <ProductDetail product={product} />;
};

const ProductDetailPageContent = () => {
  const { id } = useParams();

  if (!id) return null;

  return <ProductDetailPageContentMain id={id} />;
};

const useAutoInitializeLCA = (product: Product) => {
  const { t } = useTranslation();
  const counter = useRef(0);
  const { mutateAsync: updateLca, isPending: isUpdating } = useUpdateProductLca();

  useEffect(() => {
    // Only try the auto-initialize once
    counter.current += 1;
    if (counter.current > 1) return;

    async function update() {
      if (!product.lca_results && !isUpdating) {
        try {
          await updateLca({
            plantId: product.plant_id,
            productId: product.id,
          });
        } catch (_) {
          return showErrorToast(
            t(
              "Sorry, we couldn't initialize the LCA data for this product. Please refresh this page. If you see this again, please contact support@emidat.com.",
            ),
          );
        }
      }
    }
    update();
  }, [isUpdating, product, t, updateLca]);

  return isUpdating;
};

const ProductDetail = memo(({ product }: { product: Product }) => {
  const { data: endsOfScale = [] } = useProductImpactScale({
    plantId: product.plant_id,
    id: product.id,
  });

  const navigate = useNavigate();

  const getLinkWithParams = useGetLinkWithParams();
  const onEdit = () =>
    navigate(
      getLinkWithParams(
        !!product.production_process_id &&
          !HARDCODED_ELEMENTARIES_WITHOUT_RECIPE.includes(product.elementary_id)
          ? "/edit/product-recipe"
          : "/edit/product-specs",
        {
          selectedProduct: product.id,
        },
      ),
    );

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<"passport" | "analyse">("passport");

  const isUpdating = useAutoInitializeLCA(product);

  return (
    <PageContainer>
      <ProductDetailTopBar
        onEdit={onEdit}
        product={product}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onOpenSidePanel={() => setIsSidePanelOpen(true)}
      />
      <Suspense>
        <ProductDetailSidePanel
          product={product}
          open={isSidePanelOpen}
          setOpen={setIsSidePanelOpen}
        />
      </Suspense>
      <div
        style={{
          viewTransitionName: `product-${product.id}`,
        }}
      >
        {activeTab === "passport" && (
          <ProductDetailPagePassport
            product={product}
            endsOfScale={endsOfScale}
            isUpdating={isUpdating}
          />
        )}
        {activeTab === "analyse" && (
          <ProductDetailPageAnalyse
            isUpdating={isUpdating}
            product={product}
            endsOfScale={endsOfScale}
          />
        )}
      </div>
    </PageContainer>
  );
});
