import { useTranslation } from "react-i18next";

// Returns the locale for the given i18next "language"
const LOCALE = {
  de: new Intl.Locale("de-DE"),
  en: new Intl.Locale("en-US"),
};

export const useLocale = () => {
  const { i18n } = useTranslation();
  return LOCALE[i18n.language as keyof typeof LOCALE];
};
