import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useCountryOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        { label: t("Austria"), id: "AT" },
        { label: t("Belgium"), id: "BE" },
        { label: t("Bulgaria"), id: "BG" },
        { label: t("China"), id: "CN" },
        { label: t("Croatia"), id: "HR" },
        { label: t("Cyprus"), id: "CY" },
        { label: t("Czech Republic"), id: "CZ" },
        { label: t("Denmark"), id: "DK" },
        { label: t("Estonia"), id: "EE" },
        { label: t("Finland"), id: "FI" },
        { label: t("France"), id: "FR" },
        { label: t("Germany"), id: "DE" },
        { label: t("Greece"), id: "GR" },
        { label: t("Hungary"), id: "HU" },
        { label: t("India"), id: "IN" },
        { label: t("Ireland"), id: "IE" },
        { label: t("Italy"), id: "IT" },
        { label: t("Japan"), id: "JP" },
        { label: t("Latvia"), id: "LV" },
        { label: t("Lithuania"), id: "LT" },
        { label: t("Luxembourg"), id: "LU" },
        { label: t("Malta"), id: "MT" },
        { label: t("Netherlands"), id: "NL" },
        { label: t("Norway"), id: "NO" },
        { label: t("Poland"), id: "PL" },
        { label: t("Portugal"), id: "PT" },
        { label: t("Romania"), id: "RO" },
        { label: t("Slovakia"), id: "SK" },
        { label: t("Slovenia"), id: "SI" },
        { label: t("Spain"), id: "ES" },
        { label: t("Sweden"), id: "SE" },
        { label: t("Switzerland"), id: "CH" },
        { label: t("Turkey"), id: "TR" },
        { label: t("USA"), id: "US" },
        { label: t("United Kingdom"), id: "GB" },
      ].sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  );
};
