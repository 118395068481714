import { useCallback } from "react";
import { Profile } from "../types";
import { useApi } from "../useApi";

export const useGetProfiles = () => {
  const api = useApi<Profile[]>({
    method: "GET",
  });
  return useCallback(
    async () =>
      await api({
        url: `/v1/profiles`,
      }),
    [api],
  );
};

export const useGetMyProfile = () => {
  const api = useApi<Profile>({
    method: "GET",
  });

  return useCallback(async () => await api({ url: "/v1/me/profile" }), [api]);
};

export const usePostInviteUser = () => {
  const api = useApi({
    method: "POST",
  });
  return useCallback(
    async ({ email }: { email: string }) =>
      await api({
        url: `/v1/profiles?email=${encodeURIComponent(email)}`,
      }),
    [api],
  );
};

export const useDeleteRemoveUser = () => {
  const api = useApi({
    method: "DELETE",
  });
  return useCallback(
    async ({ profileId }: { profileId: string }) =>
      await api({
        url: `/v1/profiles/${profileId}`,
      }),
    [api],
  );
};

export const usePostTransferOwnership = () => {
  const api = useApi({
    method: "POST",
  });

  return useCallback(
    async ({ profileId }: { profileId: string }) =>
      await api({
        url: `/v1/transfer-ownership?profileId=${profileId}`,
      }),
    [api],
  );
};
