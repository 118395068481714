import { WindowOutlined } from "@mui/icons-material";
import { GridColDef, GridFilterModel, GridRowParams } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Product } from "../../api/types";
import { Badge } from "../../components/Badge";
import { DataGrid } from "../../components/DataGrid";
import { Label16 } from "../../components/Typography";
import { useLocale } from "../../localization/useLocale";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { formatDate, formatUnit } from "../../util/format";
import { useUrlPaginationSettings } from "../../util/useUrlPaginationSettings";
import { ProductStatusIndicator, useAllProductStatusses } from "./ProductStatusIndicator";
import { useGetProductLink } from "./useProductLink";
import type { useProductSelection } from "./useProductSelection";

export const ProductsListView = ({
  products,
  searchInput,
  isSelectMode,
  toggleSelect,
  selectedProducts,
  setSelectedProducts,
}: {
  products: Product[];
  searchInput?: string;
} & Pick<
  ReturnType<typeof useProductSelection>,
  "isSelectMode" | "selectedProducts" | "setSelectedProducts" | "toggleSelect"
>) => {
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const { t } = useTranslation();
  const getProductLink = useGetProductLink();

  const columnVisibilityModel = useMemo(
    () => ({
      name: true,
      category: true,
      unit: false,
      created_at: true,
      status: true,
      link: false,
    }),
    [],
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
  });

  useEffect(() => {
    setFilterModel((value) => ({
      ...value,
      quickFilterValues: searchInput?.split(" ") ?? [],
    }));
  }, [searchInput]);

  const getRow = (product: Product) => ({
    id: product.id,
    name: product.name,
    material: t(product.material),
    status: product.status,
    unit: product.unit,
    lcia_results_timestamp: product.lcia_results_timestamp,
    created_at: new Date(product.created_at),
    gwpTotal: product.lca_results?.impact_summary?.a1_a3.find(
      (i) => i.indicator_name === "GWP-total",
    ),
    link: getProductLink({ product }),
  });

  const locale = useLocale();

  const allMaterials = useMemo(() => {
    return Array.from(new Set(products.map((product) => t(product.material))));
  }, [products, t]);
  const allProductStatusses = useAllProductStatusses();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Name"),
        width: 400,
        renderCell: ({ value }) => (
          <div className="h-full flex items-center gap-2">
            {!isSelectMode && <WindowOutlined />}
            <Label16>{value}</Label16>
          </div>
        ),
      },
      {
        field: "material",
        headerName: t("Material"),
        width: 250,
        filterable: true,
        type: "singleSelect",
        valueOptions: allMaterials,
      },
      {
        field: "unit",
        headerName: t("Unit"),
        width: 120,
      },
      {
        field: "created_at",
        headerName: t("Date added"),
        width: 150,
        renderCell: ({ value }) => (value ? formatDate(value, locale) : "-"),
        filterable: true,
        type: "date",
      },
      {
        field: "status",
        headerName: t("Status"),
        width: 240,
        renderCell: ({ value }) => (
          <div className="h-full flex items-center">
            <ProductStatusIndicator status={value} />
          </div>
        ),
        filterable: true,
        type: "singleSelect",
        getOptionLabel: (value) => allProductStatusses[value as Product["status"]],
        valueOptions: Object.keys(allProductStatusses),
      },
      {
        field: "gwpTotal",
        headerName: t("GWP-total"),
        width: 160,
        sortComparator: (a, b) => {
          if (!a && !b) return 0;
          if (!a) return 1;
          if (!b) return -1;
          return b.value - a.value;
        },
        renderCell: ({ value }) =>
          value ? (
            <Badge
              size="sm"
              color="brownLight"
            >{`${value.value.toPrecision(4)} ${formatUnit(value.unit)}`}</Badge>
          ) : (
            "-"
          ),
      },
    ],
    [t, allMaterials, allProductStatusses, isSelectMode, locale],
  );

  const { page, pageSize, handlePaginationModelChange } = useUrlPaginationSettings();

  return (
    <DataGrid
      rows={products.map(getRow).sort((a, b) => a.name.localeCompare(b.name))}
      columns={columns}
      onRowClick={(params: GridRowParams) => {
        if (isSelectMode) {
          const product = products.find((p) => p.id === params.row.id);
          if (product) toggleSelect(product);
        } else {
          navigate(getLinkWithParams(params.row.link));
        }
      }}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      initialState={{
        columns: {
          columnVisibilityModel,
        },
      }}
      disableColumnFilter={false}
      checkboxSelection={isSelectMode}
      rowSelectionModel={Array.from(selectedProducts).map((p) => p.id)}
      onRowSelectionModelChange={(newSelectionModel) => {
        setSelectedProducts(new Set(products.filter((p) => newSelectionModel.includes(p.id))));
      }}
      paginationModel={{ page, pageSize }}
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
};
