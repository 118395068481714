import { useCallback } from "react";
import { Impact, PrechainProduct } from "../types";
import { useApi } from "../useApi";

export const useGetPrechainProduct = () => {
  const api = useApi<PrechainProduct>({ method: "GET" });

  return useCallback(
    ({ plantId, prechainProductId }: { plantId: string; prechainProductId: string }) =>
      api({
        url: `/v1/plants/${plantId}/prechain-products/${prechainProductId}`,
      }),
    [api],
  );
};

export const useGetPrechainProductImpacts = () => {
  const api = useApi<Impact[]>({ method: "GET" });

  return useCallback(
    ({ plantId, prechainProductId }: { plantId: string; prechainProductId: string }) =>
      api({
        url: `/v1/plants/${plantId}/prechain-products/${prechainProductId}/impacts`,
      }),
    [api],
  );
};

export const useCreatePrechainProduct = () => {
  const api = useApi<PrechainProduct>({ method: "POST" });

  return useCallback(
    ({
      plantId,
      prechainProduct,
    }: {
      plantId: string;
      prechainProduct: Omit<PrechainProduct, "id" | "product" | "plant_id" | "plant_name">;
    }) =>
      api({
        url: `/v1/plants/${plantId}/prechain-products`,
        data: prechainProduct,
      }),
    [api],
  );
};

export const useUpdatePrechainProduct = () => {
  const api = useApi<PrechainProduct>({ method: "PUT" });

  return useCallback(
    ({
      plantId,
      prechainProduct,
    }: {
      plantId: string;
      prechainProduct: Omit<PrechainProduct, "product" | "plant_id">;
    }) =>
      api({
        url: `/v1/plants/${plantId}/prechain-products/${prechainProduct.id}`,
        data: prechainProduct,
      }),
    [api],
  );
};

export const useDeletePrechainProduct = () => {
  const api = useApi<void>({ method: "DELETE" });

  return useCallback(
    ({ plantId, prechainProductId }: { plantId: string; prechainProductId: string }) =>
      api({
        url: `/v1/plants/${plantId}/prechain-products/${prechainProductId}`,
      }),
    [api],
  );
};
