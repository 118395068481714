import { ArrowBack, Article } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  useCreatePrechainProduct,
  useUpdatePrechainProduct,
} from "../../api/endpoints/prechain-products";
import { PrechainProduct, Product } from "../../api/types";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { IconContainer } from "../../components/IconContainer";
import { PageContainer } from "../../components/PageContainer";
import { TextField } from "../../components/TextField";
import { TopBar } from "../../components/TopBar";
import { Label16, Text16 } from "../../components/Typography";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { coreIndicators, filterImpact } from "../../lib/impact";
import { useElementaries } from "../../state/elementaries";
import { useActiveManufacturer } from "../../state/manufacturers";
import { usePrechainProducts } from "../../state/materials";
import { useActivePlant, usePlants } from "../../state/plants";
import { useDeletePrechainProductById } from "../../state/prechainProducts";
import { useProductImpactScale, useProducts } from "../../state/products";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { showSuccessToast } from "../../util/toasts";
import { ImpactFactors2Columns } from "../product-detail/ImpactFactors";
import { RadarChart } from "../product-detail/RadarChart";
import { DEFAULT_TRANSPORTATION, TransportationForm } from "./TransportationForm";
import { TransportationFormFields } from "./types";

interface Fields {
  plantId: string;
  productId: string;
  category: string;
  material: string;
  transportation: TransportationFormFields[];
}

export const PrechainProductForm = ({
  prechainProduct,
}: {
  prechainProduct: PrechainProduct | null;
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();

  const existingProducts = usePrechainProducts();

  const { activeManufacturer } = useActiveManufacturer();

  const nagivateBack = useCallback(() => {
    navigate(getLinkWithParams("/suppliers-and-materials"));
  }, [navigate, getLinkWithParams]);

  const methods = useForm<Fields>({
    defaultValues: {
      plantId: prechainProduct?.product?.plant_id || "",
      productId: prechainProduct?.product_id || "",
      category: "",
      material: "",
      transportation: [
        {
          modality: "truck",
          distance: undefined,
        },
      ],
    },
  });

  useEffect(
    function setInitialValues() {
      if (prechainProduct) {
        methods.reset({
          plantId: prechainProduct.product.plant_id,
          productId: prechainProduct.product.id,
          transportation: prechainProduct.transportation,
        });
      }
    },
    [prechainProduct, methods],
  );

  const { data: plants } = usePlants();
  const activePlant = useActivePlant();
  const plantOptions = plants
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ id, label: name }));

  // On plant change, fetch products and reset product field
  const plantId = methods.watch("plantId");
  const { data: products = [] } = useProducts({ plantId });

  const productOptions = products
    .filter((x) => x.status !== "incomplete")
    .filter(
      (x) =>
        x.id === prechainProduct?.product_id ||
        existingProducts.every((p) => p.product_id !== x.id),
    ) // Filter out existing products
    .map(({ id, name }) => ({ id, label: name }));

  useEffect(() => {
    if (activePlant.id === plantId) {
      methods.setValue("transportation", []);
    } else {
      methods.setValue("transportation", DEFAULT_TRANSPORTATION);
    }
  }, [activePlant, plantId, methods]);

  const { elementariesMap } = useElementaries();

  const productId = methods.watch("productId");
  const product = useMemo(() => products.find((p) => p.id === productId), [products, productId]);

  useEffect(() => {
    if (product) {
      methods.setValue("category", t(elementariesMap[product.elementary_id].category));
      methods.setValue("material", t(elementariesMap[product.elementary_id].name));
    } else {
      methods.setValue("category", "");
      methods.setValue("material", "");
    }
  }, [elementariesMap, methods, product, t]);

  const createPrechainProduct = useCreatePrechainProduct();
  const updatePrechainProduct = useUpdatePrechainProduct();
  const onSubmit = useCallback(
    async (data: Fields) => {
      const transportation = data.transportation
        .filter((t) => !!t.distance)
        .map((t) => ({
          load: null,
          utilization: null,
          modality: t.modality,
          distance: t.distance!,
        }));

      if (prechainProduct) {
        await updatePrechainProduct({
          plantId: activePlant.id,
          prechainProduct: {
            ...prechainProduct,
            transportation,
          },
        });
      } else {
        await createPrechainProduct({
          plantId: activePlant.id,
          prechainProduct: {
            product_id: data.productId,
            transportation,
          },
        });
      }

      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
      });

      nagivateBack();
      showSuccessToast(
        prechainProduct ? t("Product ingredient changed") : t("New product ingredient created"),
      );
    },
    [
      activePlant,
      prechainProduct,
      queryClient,
      nagivateBack,
      t,
      updatePrechainProduct,
      createPrechainProduct,
    ],
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deletePrechainProduct = useDeletePrechainProductById();

  const deleteProduct = useCallback(async () => {
    if (!prechainProduct) return;

    setIsDeleting(true);

    try {
      await deletePrechainProduct(prechainProduct.id);
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
      });
      nagivateBack();
      showSuccessToast(t("Product ingredient deleted"));
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  }, [activePlant.id, deletePrechainProduct, nagivateBack, prechainProduct, queryClient, t]);

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <TopBar
          title={
            prechainProduct
              ? t("Edit product (as an ingredient)")
              : t("Add new product (as an ingredient)")
          }
          icon={<ArrowBack />}
          onIconClick={nagivateBack}
          input={
            prechainProduct ? (
              <>
                <Button
                  type="button"
                  onPress={() => setShowDeleteConfirmation(true)}
                  isDisabled={isDeleting}
                  intent="secondary"
                >
                  {t("Delete Product")}
                </Button>
                <Button type="submit" intent="primary" isDisabled={isDeleting}>
                  {t("Update Product")}
                </Button>
              </>
            ) : (
              <Button type="submit">{t("Save Product")}</Button>
            )
          }
        />
        <div className="flex flex-col my-8 h-full">
          <div className="w-1/2 flex flex-row gap-3 mb-6 pr-6">
            <TextField
              placeholder={t("Supplier")}
              value={activeManufacturer.name}
              label={t("Supplier")}
              isRequired
              isDisabled
            />
            <ComboBoxFieldConnected
              name="plantId"
              isRequired
              options={plantOptions}
              isDisabled={!!prechainProduct}
              placeholder={t("Specify plant")}
              label={t("Your production plant")}
            />
          </div>
          <ComboBoxFieldConnected
            name="productId"
            isRequired
            options={productOptions}
            isDisabled={!!prechainProduct || !plantId}
            placeholder={t("Name of the product produced in your plant")}
            size="large"
            label={t("Product name")}
          />
          <div className="flex h-full divide-x">
            <div className="w-1/2 pt-6 pr-6 flex flex-col gap-6">
              <div className="grid grid-cols-2 gap-4">
                <TextFieldConnected
                  name="category"
                  isRequired
                  placeholder={t("Category")}
                  label={t("Category")}
                  isDisabled
                />
                <TextFieldConnected
                  name="material"
                  isRequired
                  placeholder={t("Material")}
                  label={t("Material")}
                  isDisabled
                />
              </div>
              {methods.watch("transportation").length > 0 && <TransportationForm />}
            </div>
            <div className="w-1/2">{product && <LinkedProductDetails product={product} />}</div>
          </div>
        </div>
        {showDeleteConfirmation && (
          <ConfirmationModal
            title={t("Delete product ingredient")}
            content={<Text16>{t("Confirm the deletion")}</Text16>}
            danger
            onConfirm={() => deleteProduct()}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
      </PageContainer>
    </FormProvider>
  );
};

const LinkedProductDetails = ({ product }: { product: Product }) => {
  const { t } = useTranslation();
  const { data: endsOfScale = [] } = useProductImpactScale({
    plantId: product.plant_id,
    id: product.id,
  });

  return (
    <div className="flex flex-col gap-6 pt-6 pl-6 h-full">
      <div className="flex gap-4 items-center">
        <IconContainer $active $blue className="shrink-0">
          <Article />
        </IconContainer>
        <div className="flex flex-col flex-1">
          <Text16>{t("Linked Product")}</Text16>
          <Label16>{product.name}</Label16>
        </div>
      </div>
      <div className="border border-gray-200 border-dashed" />
      {product.lca_results && endsOfScale && (
        <>
          <div className="flex-1 ">
            <RadarChart
              impacts={filterImpact(product.lca_results.impact_summary.a1_a3, coreIndicators)}
              endsOfScale={endsOfScale}
              product={product}
            />
          </div>
          <ImpactFactors2Columns
            impacts={filterImpact(product.lca_results.impact_summary.a1_a3, coreIndicators)}
          />
        </>
      )}
    </div>
  );
};
