import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { useProductionProcesses } from "../api/endpoints/production-processes";
import { PageContainer } from "../components/PageContainer";
import { ProductionGeneralSettingsPage } from "../page-components/plant-settings/ProductionGeneralSettingsPage";
import { ProductionOverviewPage } from "../page-components/plant-settings/ProductionOverviewPage";
import { ProductionProcessPage } from "../page-components/plant-settings/ProductionProcessPage";
import { useActiveOnboardingStep } from "../state/onboarding";
import { useActivePlant, useWasteTreatmentSchema } from "../state/plants";
import { usePeriodUnderReviewYearOptions } from "../util/usePeriodUnderReviewYearOptions";

const useDataAndSection = () => {
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section") || undefined;

  const activePlant = useActivePlant();
  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });
  const selectedProcess = productionProcesses?.find((x) => x.id === section);

  const navigate = useNavigate();
  useEffect(
    function clearSearchParamsOnPlantSwitch() {
      if (section && section !== "general" && productionProcesses.length > 0 && !selectedProcess) {
        navigate(`/production`, { replace: true });
      }
    },
    [navigate, productionProcesses, section, selectedProcess],
  );

  return { productionProcesses, selectedProcess, section };
};

export const Production = () => {
  const navigate = useNavigate();

  const yearOptions = usePeriodUnderReviewYearOptions();
  const initialActiveYear = yearOptions[yearOptions.length - 1].id;
  const [_activeYear, setActiveYear] = useLocalStorage<number>(
    "production-activeYear",
    initialActiveYear,
  );
  const activeYear = _activeYear ?? initialActiveYear;

  const { productionProcesses, selectedProcess, section } = useDataAndSection();

  const { data: wasteTreatmentSchema } = useWasteTreatmentSchema();

  const { step: onboardingStep } = useActiveOnboardingStep();
  useEffect(() => {
    if (onboardingStep < 2) {
      navigate("/onboarding");
    }
  }, [onboardingStep, navigate]);

  return (
    <PageContainer>
      {!section && (
        <ProductionOverviewPage
          processes={productionProcesses}
          wasteTreatmentSchema={wasteTreatmentSchema}
        />
      )}
      {section === "general" && (
        <ProductionGeneralSettingsPage wasteTreatmentSchema={wasteTreatmentSchema} />
      )}
      {!!section && section !== "general" && selectedProcess && (
        <ProductionProcessPage
          activeYear={activeYear}
          process={selectedProcess}
          yearOptions={yearOptions}
          setActiveYear={setActiveYear}
        />
      )}
    </PageContainer>
  );
};
