import { useAuth0 } from "@auth0/auth0-react";

export const useMaxPlantsCount = () => {
  const { user } = useAuth0();

  if (user?.["sub"]?.startsWith("samlp|")) {
    // Users who authenticate through SAML are special,
    // we don't set a plant limit for them.
    return Infinity;
  }

  return user?.["app_metadata"]["max_plants"] || 3;
};
