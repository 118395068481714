import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router";
import useBeforeUnload from "react-use/lib/useBeforeUnload";
import { showWarningToast } from "./toasts";

export const useWarnBeforeUnload = (
  isDirty: boolean,
  { withBlocker }: { withBlocker: boolean } = { withBlocker: false },
) => {
  const { t } = useTranslation();

  // TODO: Note that we have to be careful to not use multiple useBlocker hooks
  //       on the same page, because that's not supported yet:
  // https://github.com/remix-run/react-router/discussions/9978
  // https://github.com/remix-run/react-router/pull/10780
  const blocker = useBlocker(isDirty && withBlocker);
  const prevState = useRef(blocker.state);
  useEffect(() => {
    if (prevState.current !== blocker.state && blocker.state === "blocked") {
      showWarningToast(
        t("You've got unsaved changes. To leave, please cancel or submit the edit."),
      );
      blocker.reset();
    }
    prevState.current = blocker.state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, blocker.state, blocker.reset]);

  useBeforeUnload(isDirty, t("You have unsaved changes. Are you sure you want to leave?"));
};
