import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { EndOfScale, Impact, LciaResults } from "../../api/types";
import { tooltipStyles } from "../../components/Tooltip";
import { Label12, Text14 } from "../../components/Typography";
import { coreIndicators, filterImpact } from "../../lib/impact";
import { formatPrecision } from "../../util/format";
import { useImpactColors } from "./useImpactColors";

const useStageMap = () => {
  const { t } = useTranslation();
  return {
    [t("Raw materials")]: "A1",
    [t("Transportation")]: "A2",
    [t("Manufacturing")]: "A3",
  };
};

export const LifeCycleA1A3Chart = ({
  lcaResults,
  endsOfScale,
}: {
  lcaResults: LciaResults;
  endsOfScale: EndOfScale[];
}) => {
  const colors = useImpactColors();
  const { t } = useTranslation();

  const scaleMap: Record<string, number> = Object.fromEntries(
    endsOfScale.map((scale) => [scale.indicator_name, scale.value]),
  );
  const stageMap = useStageMap();

  const stageFactory = (stage: string, impact: Impact[]) =>
    impact.reduce(
      (acc, impact) => ({
        ...acc,
        [impact.indicator_name]: impact.value / scaleMap[impact.indicator_name],
        [impact.indicator_name + "_unit"]: impact.unit,
        [impact.indicator_name + "_actual"]: impact.value,
      }),
      {
        stage,
      },
    );

  // Create data for A1, A2, and A3 stages
  const rawMaterialsImpact = filterImpact(lcaResults.impact_summary.a1, coreIndicators);
  const transportationImpact = filterImpact(lcaResults.impact_summary.a2, coreIndicators);
  const manufacturingImpact = filterImpact(lcaResults.impact_summary.a3, coreIndicators);

  const stages = [
    stageFactory(t("Raw materials"), rawMaterialsImpact),
    stageFactory(t("Transportation"), transportationImpact),
    stageFactory(t("Manufacturing"), manufacturingImpact),
  ];

  return (
    <ResponsiveBar
      data={stages}
      keys={Array.from(coreIndicators)}
      indexBy="stage"
      margin={{ top: 10, right: 0, bottom: 50, left: 60 }}
      padding={0.2}
      innerPadding={4}
      groupMode="grouped"
      colors={(x) => colors[x.id]}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: [-1, -0.5, 0, 0.5, 1],
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        renderTick: ({ value, x, y }) => {
          return (
            <g transform={`translate(${x},${y})`}>
              <foreignObject x="-120" y="10" width="240" height="60">
                <div className="flex flex-col items-center">
                  <Label12>{value}</Label12>
                  <Label12>({stageMap[value as keyof typeof stageMap]})</Label12>
                </div>
              </foreignObject>
            </g>
          );
        },
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      gridYValues={[-1, -0.5, 0, 0.5, 1]}
      minValue={-1}
      maxValue={1}
      barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in stage: " + e.indexValue}
      enableLabel={false}
      tooltip={({ id, data }) => {
        const typedData = data as Record<string, string | number>;
        return (
          <div className={tooltipStyles()}>
            <Text14>
              {id}{" "}
              <strong>
                {formatPrecision(Number(typedData[`${id}_actual`]), 4)} {typedData[`${id}_unit`]}
              </strong>
            </Text14>
          </div>
        );
      }}
    />
  );
};
